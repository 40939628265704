import { gql, useQuery } from "@apollo/client";
import Content from "components/Content/Content";
import { GravityForm } from "components/GravityForm";
import { Loading } from "features";
import { FlexibleContentBlocksForm } from "graphql";
import { useEffect, useState } from "react";
import Balancer from "react-wrap-balancer";
import { FeaturedImage } from "components/FeaturedImage";
import { BgGraphic } from "components/BgGraphic";

const radar = {
  sourceUrl: "/svg-bgs/radar.svg",
  mediaDetails: { width: 457.52, height: 606.38 },
  altText: "Superhero graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const spaceShuttle = {
  sourceUrl: "/svg-bgs/space-shuttle.svg",
  mediaDetails: { width: 485.58, height: 464.46 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface FormProps extends FlexibleContentBlocksForm {
  className?: string;
  bgImage?: string;
}

const Form = ({
  className,
  sectionId,
  title,
  content,
  form,
  backgroundImage,
  backgroundColor,
  bgImage,
}: FormProps) => {
  const formId = Number(form);

  const { data, loading, error } = useQuery(FORM_FRAGMENT, {
    variables: {
      id: formId,
    },
  });

  const [formData, setFormData] = useState({} as any);
  const hasForm = formData && form;

  useEffect(() => {
    if (data) {
      setFormData(data?.gfForm);
    }
  }, [data]);

  if (loading) {
    return (
      <div className={`${className} relative my-12 w-full px-6 md:px-0`}>
        <div className={`container mx-auto text-center`}>
          <Loading />
        </div>
      </div>
    );
  }

  const noBgImage = backgroundImage && backgroundImage[0] === "none";

  let bgGraphicType = "" || bgImage;
  if (backgroundImage && backgroundImage[0] === "planet-rocketship") {
    bgGraphicType = "planet-rocketship";
  } else if (backgroundImage && backgroundImage[0] === "rocketship-planet") {
    bgGraphicType = "rocketship-planet";
  } else if (backgroundImage && backgroundImage[0] === "astronaut-comet") {
    bgGraphicType = "astronaut-comet";
  } else if (backgroundImage && backgroundImage[0] === "comet-astronaut") {
    bgGraphicType = "comet-astronaut";
  } else if (backgroundImage && backgroundImage[0] === "radar-spaceshuttle") {
    bgGraphicType = "radar-spaceshuttle";
  } else if (backgroundImage && backgroundImage[0] === "spaceshuttle-radar") {
    bgGraphicType = "spaceshuttle-radar";
  } else if (backgroundImage && backgroundImage[0] === "comet-right") {
    bgGraphicType = "comet-right";
  } else if (backgroundImage && backgroundImage[0] === "comet-left") {
    bgGraphicType = "comet-left";
  } else if (backgroundImage && backgroundImage[0] === "radar-right") {
    bgGraphicType = "radar-right";
  } else if (backgroundImage && backgroundImage[0] === "radar-left") {
    bgGraphicType = "radar-left";
  }

  return (
    <div
      id={sectionId ?? ``}
      className={`${className} relative w-full md:px-0 grid overflow-hidden border-t-8 border-primary-light`}
    >
      <div
        className={`relative z-10 container mx-auto flex flex-col gap-6 text-center pt-20 pb-14 px-4 md:px-8`}
      >
        {title && (
          <h2
            className={`font-heading uppercase text-3xl md:text-4xl font-bold text-primary`}
          >
            <Balancer>{title}</Balancer>
          </h2>
        )}
        {content && (
          <Content
            className={`text-md mx-auto my-4 max-w-xl font-sans text-gray-800`}
            content={content}
          />
        )}
        <GravityForm form={formData} formId={formId ?? 1} />
      </div>
      {!noBgImage && (
        <div
          className={`absolute w-full h-full z-0`}
          style={{ gridArea: "1/1" }}
        >
          {/* {superheroStar && (
            <>
              <FeaturedImage
                className={`absolute top-4 -left-52 w-[45rem]`}
                image={radar}
              />
              <FeaturedImage
                className={`hidden md:block absolute bottom-12 right-6 w-[20rem]`}
                image={spaceShuttle}
              />
            </>
          )} */}
          <BgGraphic bgType={bgGraphicType} />
        </div>
      )}
    </div>
  );
};

export default Form;

export const FORM_BLOCK_FRAGMENT = gql`
  fragment FormBlockFragment on FlexibleContentBlocksForm {
    sectionId
    title
    content
    backgroundImage
    backgroundColor
    form
  }
`;

const FORM_FRAGMENT = gql`
  query FormQuery($id: ID!) {
    gfForm(id: $id, idType: DATABASE_ID) {
      id
      title
      submitButton {
        text
      }
      confirmations {
        message
      }
      cssClass
      formFields {
        nodes {
          databaseId
          type

          ... on ConsentField {
            id
            label
            checkboxLabel
            value
            description
            isRequired
            labelPlacement
            type
            cssClass
          }
          ... on NameField {
            databaseId
            type
            label
            description
            cssClass
            isRequired
            inputs {
              id
              label
              ... on NameInputProperty {
                id
                name
                placeholder
                label
                key
                isHidden
                hasChoiceValue
                defaultValue
                customLabel
                choices {
                  isSelected
                  text
                  value
                }
                autocompleteAttribute
              }
            }
          }
          ... on EmailField {
            databaseId
            adminLabel
            canPrepopulate
            cssClass
            databaseId
            description
            descriptionPlacement
            displayOnly
            errorMessage
            hasAutocomplete
            hasEmailConfirmation
            inputType
            inputName
            isRequired
            label
            placeholder
            visibility
          }
          ... on TextField {
            databaseId
            label
            description
            cssClass
            isRequired
            placeholder
          }
          ... on TextAreaField {
            databaseId
            label
            description
            cssClass
            isRequired
            placeholder
          }
          ... on CheckboxField {
            canPrepopulate
            checkboxValues {
              inputId
              text
              value
            }
            choices {
              ... on CheckboxFieldChoice {
                isSelected
                text
                value
              }
              value
              text
            }
            cssClass
            databaseId
            description
            displayOnly
            errorMessage
            hasChoiceValue
            hasSelectAll
            inputName
            inputType
            inputs {
              ... on CheckboxInputProperty {
                id
                name
                label
              }
            }
            isRequired
            label
            labelPlacement
            type
            value
          }
          ... on MultiSelectField {
            id
            choices {
              ... on MultiSelectFieldChoice {
                isSelected
                text
                value
              }
            }
            cssClass
            databaseId
            errorMessage
            displayOnly
            description
            hasChoiceValue
            hasEnhancedUI
            inputName
            inputType
            inputs {
              ... on CheckboxInputProperty {
                id
                name
                label
              }
            }
            isRequired
            label
            type
            value
            values
          }
          ... on SelectField {
            canPrepopulate
            choices {
              ... on SelectFieldChoice {
                isSelected
                text
                value
              }
            }
            autocompleteAttribute
            cssClass
            databaseId
            defaultValue
            description
            displayOnly
            errorMessage
            hasAutocomplete
            hasChoiceValue
            hasEnhancedUI
            inputs {
              id
              label
            }
            isRequired
            label
            placeholder
            shouldAllowDuplicates
            type
          }
          ... on WebsiteField {
            databaseId
            placeholder
            label
            description
            isRequired
            cssClass
            value
          }
          ... on PhoneField {
            databaseId
            label
            description
            cssClass
            isRequired
            placeholder
          }
          ... on DateField {
            databaseId
            label
            description
            cssClass
            isRequired
            placeholder
          }

          ... on NumberField {
            databaseId
            label
            description
            cssClass
            isRequired
            placeholder
          }
          ... on RadioField {
            choices {
              text
              value
              ... on RadioFieldChoice {
                isOtherChoice
                isSelected
                text
                value
              }
            }
            cssClass
            conditionalLogic {
              actionType
              logicType
              rules {
                fieldId
                operator
                value
              }
            }
            databaseId
            description
            displayOnly
            errorMessage
            hasChoiceValue
            hasOtherChoice
            inputType
            inputs {
              id
              label
            }
            isRequired
            label
            shouldAllowDuplicates
            type
            value
          }
          ... on AddressField {
            addressType
            addressValues {
              city
              country
              lineTwo
              state
              street
              zip
            }
            adminLabel
            canPrepopulate
            cssClass
            databaseId
            defaultCountry
            defaultProvince
            defaultState
            description
            descriptionPlacement
            displayOnly
            errorMessage
            hasAutocomplete
            inputName
            inputType
            inputs {
              label
              id
              ... on AddressInputProperty {
                id
                name
                autocompleteAttribute
                customLabel
                defaultValue
                isHidden
                key
                label
                placeholder
              }
            }
            isRequired
            label
            shouldCopyValuesOption
            type
            value
          }
        }
      }
    }
  }
`;
