import { useQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Blocks } from "features"; // Blocks eventually
import { NavigationMenu, Form } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
} from "fragments";

export default function Component() {
  const { data, loading, error } = useQuery(Component.query, {
    variables: Component.variables(),
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { page, headerMenuItems, footerMenuItems, siteSettings } = data;
  const { seo, title, flexibleContent } = page;
  const { blocks } = flexibleContent;
  const {
    location,
    locationName,
    logo,
    socialLinks,
    showDates,
    ctaLink,
    countdownDate,
    ticketsOnSale,
    generalAdmissionTickets,
  } = siteSettings.siteSettingFields;
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = socialLinks;

  return (
    <Layout
      isHomePage={true}
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo.node}
      ctaLink={ctaLink}
      countDownDate={countdownDate}
      dates={showDates}
      locationName={locationName}
      location={location}
      facebookUrl={facebookUrl}
      instagramUrl={instagramUrl}
      twitterUrl={twitterUrl}
      youtubeUrl={youtubeUrl}
      ticketsOnSale={ticketsOnSale}
      generalTickets={generalAdmissionTickets}
    >
      <Blocks blocks={blocks} />
      <Form
        className={``}
        sectionId={`newsletter`}
        title={`GET THE LATEST SHOW UPDATES`}
        form={`2`}
        bgImage={`radar-spaceshuttle`}
        backgroundColor={`white`}
      />
    </Layout>
  );
}

Component.query = gql`
  query HomePage(
    $asPreview: Boolean = false
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
  ) {
    page(id: "/", idType: URI, asPreview: $asPreview) {
      id
      title
      seo {
        ...SEOFragment
      }
      flexibleContent {
        ...BlocksFragment
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${Blocks.fragments.entry}
`;

Component.variables = () => {
  return {
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
  };
};
