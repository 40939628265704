import { motion, useAnimation } from "framer-motion";
import useMediaQuery from "utilities/useMediaQuery";
import clsx from "clsx";
import React from "react";

interface CarouselProps {
  className?: string;
  items: any[];
  renderSlide: (item: any, index: number) => JSX.Element;
  arrows?: boolean;
  numNgroups?: number;
}

const Carousel = ({
  className,
  items,
  renderSlide,
  arrows,
  numNgroups,
}: CarouselProps) => {
  const isMobile = useMediaQuery("(max-width: 868px)");

  const mathNum = (numNgroups || 1) > 1 ? 2 : 1;

  const controls = useAnimation();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const itemsLength = items.length / (isMobile ? mathNum : numNgroups || 1);

  const itemsSplitIntoGroups = items.reduce((acc, curr, index) => {
    const groupIndex = Math.floor(
      index / (isMobile ? mathNum : numNgroups || 1),
    );
    const group = acc[groupIndex] || [];
    group.push(curr);
    acc[groupIndex] = group;
    return acc;
  }, [] as any[]);

  const grpNum = itemsSplitIntoGroups.length;

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
    controls.start({
      x: -index * 100 + "%",
    });
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      goToSlide(currentSlide - 1);
    } else {
      goToSlide(grpNum - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide < grpNum - 1) {
      goToSlide(currentSlide + 1);
    } else {
      goToSlide(0);
    }
  };

  return (
    <>
      <div className={`relative w-full h-full overflow-hidden ${className}`}>
        <motion.div
          className="relative w-full h-full"
          initial={{ x: 0 }}
          animate={controls}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <div
            className={`flex h-full gap-4`}
            style={{ width: `${itemsLength * 100}%` }}
          >
            {items.map((item, index) => renderSlide(item, index))}
          </div>
        </motion.div>
      </div>

      <div className="relative min-w-[50%] mx-[15%] mt-2 mdl:mt-0 flex gap-6 list-none justify-center items-center p-0">
        <div>
          {itemsSplitIntoGroups &&
            itemsSplitIntoGroups.map((_: any, index: number) => {
              const buttonClass = clsx(
                "mx-2 h-[12px] w-[12px] flex-initial cursor-pointer rounded-full hover:bg-primary-light hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] transition-colors transition-shadow ease-in-out motion-reduce:transition-none",
                index === currentSlide
                  ? "bg-secondary shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
                  : "bg-slate shadow-[inset_0px_4px_4px_0px_rgba(0,0,0,0.25)]",
              );
              return (
                <button
                  key={index}
                  type="button"
                  className={buttonClass}
                  aria-current={index === currentSlide ? "true" : "false"}
                  aria-label={`Slide ${index + 1}`}
                  onClick={() => goToSlide(index)}
                ></button>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Carousel;
