import { gql } from "@apollo/client";
import { FlexibleContentBlocksFaq } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Accordion } from "components/Accordion";
import { Content } from "components/Content";
import { FeaturedImage } from "../FeaturedImage/index";

interface FAQProps extends FlexibleContentBlocksFaq {
  className?: string;
}

const FAQ = ({ title, content, hasSideImage, sideImage, items }: FAQProps) => {
  return (
    <div className={``}>
      {hasSideImage && <FeaturedImage className={``} image={sideImage} />}
      <div className={``}>
        {title && <h2 className={`text-3xl`}>{title}</h2>}
        {content && (
          <Content className={`text-lg font-body`} content={content} />
        )}
        <div className={`mt-4`}>
          {items &&
            items.map((item, index) => {
              const question = item?.question || "";
              const answer = item?.answer || "";

              return (
                <div className={``} key={`faq-item-${index}`}>
                  <Accordion
                    question={question}
                    answer={answer}
                    index={index}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;

FAQ.fragments = {
  entry: gql`
    fragment FAQFragment on FlexibleContentBlocksFaq {
      fieldGroupName
      content
      title
      hasSideImage
      sideImage {
        node {
          ...MediaItemFragment
        }
      }
      items {
        answer
        question
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
