import { FeaturedImage } from "components/FeaturedImage";
import Comet from "public/svg-bgs/comet";
import Planet from "public/svg-bgs/planet";
import Radar from "public/svg-bgs/radar";
import Rocket from "public/svg-bgs/rocket";
import Shuttle from "public/svg-bgs/shuttle";
import Astronaut from "public/svg-bgs/astronaut";

const planetStar = {
  sourceUrl: "/svg-bgs/planet-star.svg",
  mediaDetails: { width: 406.24, height: 462.73 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const rocketShip = {
  sourceUrl: "/svg-bgs/rocket-ship.svg",
  mediaDetails: { width: 553.5, height: 597.44 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const cometStar = {
  sourceUrl: "/svg-bgs/comet-star.svg",
  mediaDetails: { width: 414.03, height: 756.74 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const astronaut = {
  sourceUrl: "/svg-bgs/astronaut.svg",
  mediaDetails: { width: 515.54, height: 599.68 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const radar = {
  sourceUrl: "/svg-bgs/radar.svg",
  mediaDetails: { width: 457.52, height: 606.38 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const spaceShuttle = {
  sourceUrl: "/svg-bgs/space-shuttle.svg",
  mediaDetails: { width: 485.58, height: 464.46 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface BgGraphicProps {
  bgType: any;
  fillColor?: string;
}

const BgGraphic = ({ bgType, fillColor }: BgGraphicProps) => {
  let name = bgType;

  switch (name) {
    case "planet-rocketship": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute top-8 left-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={planetStar}
          /> */}
          <Planet className={`absolute top-8 left-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          {/* <FeaturedImage
            className={`absolute bottom-12 right-6 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={rocketShip}
          /> */}
          <Rocket className={`absolute bottom-12 right-6 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "rocketship-planet": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute top-8 right-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={planetStar}
          /> */}
          <Planet className={`absolute top-8 right-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          {/* <FeaturedImage
            className={`absolute bottom-12 left-6 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={rocketShip}
          /> */}
          <Rocket className={`absolute bottom-12 left-6 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "astronaut-comet": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-12 left-4 w-[17rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={astronaut}
          />
          {/* <FeaturedImage
            className={`absolute top-12 right-4 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={cometStar}
          /> */}
          <Comet className={`absolute top-12 right-4 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "comet-astronaut": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-12 right-4 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={astronaut}
          />
          {/* <FeaturedImage
            className={`absolute top-12 left-4 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={cometStar}
          /> */}
          <Comet className={`absolute top-12 left-4 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "radar-spaceshuttle": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute bottom-8 left-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={radar}
          /> */}
          <Radar className={`absolute bottom-8 left-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          {/* <FeaturedImage
            className={`absolute top-8 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={spaceShuttle}
          /> */}
          <Shuttle className={`absolute top-8 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "spaceshuttle-radar": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute bottom-8 right-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={radar}
          /> */}
          <Radar className={`absolute bottom-8 right-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          {/* <FeaturedImage
            className={`absolute top-8 left-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={spaceShuttle}
          /> */}
          <Shuttle className={`absolute top-8 left-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "planet-spaceshuttle": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute top-8 left-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={planetStar}
          /> */}
          <Planet className={`absolute top-8 left-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          {/* <FeaturedImage
            className={`absolute bottom-12 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={spaceShuttle}
          /> */}
          <Shuttle className={`absolute bottom-12 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "spaceshuttle-planet": {
      return (
        <>
          {/* <FeaturedImage
            className={`absolute top-8 right-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={planetStar}
          /> */}
          <Planet className={`absolute top-8 right-8 w-[24rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
          <FeaturedImage
            className={`absolute bottom-12 left-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
            image={spaceShuttle}
          />
          <Shuttle className={`absolute bottom-12 left-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
        </>
      );
    }
    case "comet-right": {
      return (
        // <FeaturedImage
        //   className={`absolute top-12 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
        //   image={cometStar}
        // />
        <Comet className={`absolute top-12 right-6 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
      );
    }
    case "comet-left": {
      return (
        // <FeaturedImage
        //   className={`absolute top-12 -left-36 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
        //   image={cometStar}
        // />
        <Comet className={`absolute top-12 -left-36 w-[20rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
      );
    }
    case "radar-right": {
      return (
        // <FeaturedImage
        //   className={`absolute bottom-8 right-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
        //   image={radar}
        // />
        <Radar className={`absolute bottom-8 right-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
      );
    }
    case "radar-left": {
      return (
        // <FeaturedImage
        //   className={`absolute bottom-8 left-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`}
        //   image={radar}
        // />
        <Radar className={`absolute bottom-8 left-6 w-[15rem] ${fillColor ?? 'fill-[rgba(125,139,160,.2)]'}`} />
      );
    }

    default: {
      return null;
    }
  }
};

export default BgGraphic;
