import { gql } from "@apollo/client";
import { FlexibleContentBlocksStarGuests } from "graphql";
import { MEDIA_ITEM_FRAGMENT, CELEB_INFO_FRAGMENT } from "fragments";
import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import { FeaturedImage } from "components/FeaturedImage";
import { BgGraphic } from "components/BgGraphic";

interface GuestsProps extends FlexibleContentBlocksStarGuests {
  className?: string;
}

const Guests = ({ className, variant, title, starCtas, stars }: GuestsProps) => {
  const isCarousel = variant && variant[0] === "carousel";
  const isGrid = variant && variant[0] === "grid";

  const hasCelebs = stars?.nodes && stars?.nodes?.length > 0;
  const celebCount = stars?.nodes?.length ?? 0;

  let wrapperClass = clsx(`bg-white grid overflow-hidden relative`);

  let innerWrapperClass = clsx(
    `flex flex-col items-center justify-center container mx-auto px-4 md:px-8 py-6 z-10 relative h-full min-h-[530px]`,
  );

  let secTitleClass = clsx(`w-full py-2`);
  let titleClass = clsx(
    `font-heading max-[320px]:text-3xl text-4xl md:text-5xl font-bold text-center text-primary tracking-wider`,
  );

  let celebVariant = clsx(
    `justify-center items-center gap-4 w-full mdl:w-[75%] my-4`,
    isGrid &&
      (celebCount >= 8 || celebCount === 4) &&
      `grid max-[320px]:grid-cols-1 grid-cols-2 mdl:grid-cols-4`,
    isGrid && celebCount <= 7 && celebCount !== 4 && `flex flex-wrap`,
  );

  let celebItemClass = clsx(
    `group`,
    celebCount <= 7 && celebCount !== 4
      ? `max-w-[216px]`
      : `mx-auto max-w-full`,
  );

  let celebItemImgClass = clsx(
    `w-full h-full grayscale group-hover:grayscale-0 transition duration-300 ease-in-out`,
  );

  let celebItemContentClass = clsx(
    `flex flex-col min-h-[68px] h-[110px] lg:h-[96px] items-center justify-center w-full text-center bg-primary group-hover:bg-primary-light p-2 text-slate transition duration-300 ease-in-out`,
  );

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerWrapperClass}>
        <div className={secTitleClass}>
          {title ? <h2 className={titleClass}>{title}</h2> : null}
        </div>
        {hasCelebs ? (
          <div className={celebVariant}>
            {stars?.nodes?.map((celeb: any, index) => {
              const {
                id,
                title,
                uri,
                guestProps: { guestDays, headshot },
              } = celeb;

              let oneDate = guestDays && guestDays.length === 1;
              let formattedFirstDate = "";
              let formattedLastDateNoMonth = "";
              let formattedDateRange = "";

              if(guestDays) {
                oneDate = guestDays && guestDays.length === 1;
              const firstDate = guestDays && guestDays[0]?.guestDate;
              const lastDate =
                guestDays && guestDays[guestDays.length - 1]?.guestDate;

              const trimFirst = firstDate.split("T")[0];
              const [fyear, fmonth, fday] = trimFirst.split("-");
              const formFirst = `${fmonth}/${fday}/${fyear}`;
              const parseFirst = Date.parse(formFirst);
              formattedFirstDate = new Date(
                parseFirst,
              ).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
              });

              const trimLast = lastDate.split("T")[0];
              const [lyear, lmonth, lday] = trimLast.split("-");
              const formLast = `${lmonth}/${lday}/${lyear}`;
              const parseLast = Date.parse(formLast);
              // const formattedLastDateMonth = new Date(
              //   parseLast,
              // ).toLocaleDateString("en-US", {
              //   month: "long",
              //   day: "numeric",
              // });
              formattedLastDateNoMonth = new Date(
                parseLast,
              ).toLocaleDateString("en-US", {
                day: "numeric",
              });
              formattedDateRange = `${formattedFirstDate} - ${formattedLastDateNoMonth}`;
              }

              return (
                <div className={celebItemClass} key={`${index}-${celeb?.id}`}>
                  <Link href={uri}>
                    <div className={celebItemImgClass}>
                      <FeaturedImage className={``} image={headshot.node} />
                    </div>
                    <div className={celebItemContentClass}>
                      <h3 className={`text-xl`}>{title}</h3>
                      {guestDays && (
                        <>
                          {oneDate ? (
                            <p className={`mb-0 font-body`}>
                              {formattedFirstDate}
                            </p>
                          ) : (
                            <p className={`mb-0 font-body`}>
                              {formattedDateRange}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={celebVariant}>
            {/* <Image
              className={`${celebItemClass} mx-auto`}
              src="/mystery-head.jpg"
              alt="Mystery Celeberity"
              width={200}
              height={200}
            /> */}
            <Image
              className={`${celebItemClass} mx-auto`}
              src="/mystery-head.jpg"
              alt="Mystery Celeberity"
              width={200}
              height={200}
            />
            <Image
              className={`${celebItemClass} mx-auto`}
              src="/mystery-head.jpg"
              alt="Mystery Celeberity"
              width={200}
              height={200}
            />
            <Image
              className={`${celebItemClass} mx-auto`}
              src="/mystery-head.jpg"
              alt="Mystery Celeberity"
              width={200}
              height={200}
            />
          </div>
        )}
        <div className={`mt-8`}>
          {starCtas &&
            starCtas.map((cta, index) => {
              const link = cta?.link;

              const isPrimary = cta?.type && cta?.type[0] === "primary";
              const isPrimOut = cta?.type && cta?.type[0] === "primary-outline";
              const isPrimLight = cta?.type && cta?.type[0] === "primary-light";
              const isPrimLightOut =
                cta?.type && cta?.type[0] === "primary-light-outline";
              const isSecondary = cta?.type && cta?.type[0] === "secondary";
              const isSecOut =
                cta?.type && cta?.type[0] === "secondary-outline";

              const btnClass = clsx(
                isPrimary &&
                  `rounded-md border-primary border-2 bg-primary py-2 px-4 font-body font-bold text-white uppercase hover:bg-primary-light transition duration-300 ease-in-out hover:shadow-lg hover:border-primary-light hover:text-white tracking-wider cursor-pointer`,
                isPrimOut &&
                  `rounded-md border-primary border-2 bg-white py-2 px-4 font-body font-bold text-primary uppercase hover:bg-primary-light transition duration-300 ease-in-out hover:shadow-lg hover:border-primary-light hover:text-white tracking-wider cursor-pointer`,
                isPrimLight &&
                  `bg-primary-light rounded-md border-2 border-primary-light py-2 px-4 font-body font-bold text-white uppercase hover:bg-white hover:text-primary-light hover:border-primary-light transition duration-300 ease-in-out tracking-wider cursor-pointer`,
                isPrimLightOut &&
                  `bg-white rounded-md border-2 border-primary-light py-2 px-4 font-body font-bold text-white uppercase hover:bg-primary-light hover:text-primary-light hover:border-primary-light transition duration-300 ease-in-out tracking-wider cursor-pointer`,
                isSecondary &&
                  `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider cursor-pointer`,
                isSecOut &&
                  `bg-white rounded-md border-2 border-secondary py-2 px-4 font-body font-bold text-secondary uppercase hover:bg-secondary hover:text-black hover:border-secondary transition duration-300 ease-in-out tracking-wider cursor-pointer`,
              );

              return (
                <Link
                  key={`${index}-${cta}`}
                  href={link?.url ?? ""}
                  target={link?.target ?? "_self"}
                  className={`${btnClass}`}
                >
                  {link?.title}
                </Link>
              );
            })}
        </div>
      </div>
      <div className={bgItemClass} style={{ gridArea: "1/1" }}>
        <BgGraphic bgType={"astronaut-comet"} />
      </div>
    </section>
  );
};

export default Guests;

Guests.fragments = {
  entry: gql`
    fragment GuestsFragment on FlexibleContentBlocksStarGuests {
      __typename
      variant
      title
      starCtas {
        type
        link {
          title
          url
          target
        }
      }
      stars {
        nodes {
          ... on Celebrity {
            ...CelebInfoFragment
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
    ${CELEB_INFO_FRAGMENT}
  `,
};
