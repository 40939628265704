import { gql } from "@apollo/client";
import { FlexibleContentBlocksTabsLayout } from "graphql";
import TabContent from "./Fragments/TabContent";
import { SplitContent, TextImage, TextBlock, FAQ } from "components";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { useState, useId, useEffect } from "react";
import { motion, m } from "framer-motion";
import clsx from "clsx";

interface TabsBlockProps extends FlexibleContentBlocksTabsLayout {
  classname?: string;
  index?: number;
}

const tabContentVariant = {
  active: {
    display: "block",
    scale: 1,
  },
  inactive: {
    scale: 0.1,
    display: "none",
  },
};

const TabsBlock = ({ tabsRepeater, index }: TabsBlockProps) => {
  const tab = tabsRepeater && tabsRepeater[0];

  const tabCount = tabsRepeater && tabsRepeater.length;

  const [selectedTab, setSelectedTab] = useState(tab?.tabLabel ?? "Accessibility");

  return (
    <section className={clsx(`p-2 mdl:p-6 max-w-screen-2xl mx-auto`)}>
      <div className={`border-b-2 border-primary pb-4`}>
        <div
          className={clsx(
            `mx-auto w-fit grid ${
              tabCount === 1 ? `grid-cols-1` : `grid-cols-2`
            } ${tabCount !== 1 && `md:grid-cols-${tabCount}`}`,
          )}
        >
          {tabsRepeater &&
            tabsRepeater.map((tab, index) => {
              const tabLabel = tab?.tabLabel;
              return (
                <motion.button
                  key={`${tabLabel}-${index}`}
                  onClick={() => setSelectedTab(tabLabel as string)}
                  className={`${
                    tabLabel === selectedTab
                      ? "active text-white"
                      : "inactive hover:text-primary-light"
                  } relative px-3 py-1.5 text-xl font-body text-primary transition`}
                >
                  {tabLabel === selectedTab && (
                    <motion.span
                      className="absolute inset-0 bg-primary-light rounded-md"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.3,
                        type: "spring",
                      }}
                    />
                  )}
                  <span className={`relative z-10`}>{tabLabel}</span>
                </motion.button>
              );
            })}
        </div>
      </div>
      {tabsRepeater &&
        tabsRepeater.map((tab, index) => {
          const blocks = tab?.tabContentBlocks;
          const tabLabel = tab?.tabLabel;
          
          return (
            <motion.div
              layout
              role="tabpanel"
              id={`${tabLabel}`}
              key={selectedTab ? `${tabLabel}-${index}` : `empty`}
              className={`${
                tabLabel === selectedTab ? `block` : `hidden`
              } mt-4`}
              variants={tabContentVariant}
              animate={tabLabel === selectedTab ? "active" : "inactive"}
              transition={{
                duration: 0.3,
                type: "spring",
              }}
            >
              <TabContent keyName={`${tabLabel}-${index}`} blocks={blocks} />
            </motion.div>
          );
        })}
    </section>
  );
};

export default TabsBlock;

TabsBlock.fragments = {
  entry: gql`
    fragment TabsBlockFragment on FlexibleContentBlocksTabsLayout {
      fieldGroupName
      tabsRepeater {
        tabLabel
        tabContentBlocks {
          fieldGroupName
          ... on FlexibleContentBlocksTabsRepeaterTabContentBlocksFaq {
            content
            title
            hasSideImage
            sideImage {
              node {
                ...MediaItemFragment
              }
            }
            items {
              answer
              question
            }
          }
          ... on FlexibleContentBlocksTabsRepeaterTabContentBlocksSplitContent {
            __typename
            sectionTitle
            sideOneTitle
            sideOneContent
            sideTwoTitle
            sideTwoContent
            sideOneImage {
              node {
                ...MediaItemFragment
              }
            }
            sideTwoImage {
              node {
                ...MediaItemFragment
              }
            }
          }
          ... on FlexibleContentBlocksTabsRepeaterTabContentBlocksTextImage {
            __typename
            ankerLinkId
            variant
            sectionTitle
            sectionContent
            afterImageContent
            signatureName
            writersPosition
            hasAppLinks
            ctaRepeater {
              type
              link {
                title
                url
                target
              }
            }
            appleAppLink {
              title
              url
              target
            }
            googleAppLink {
              title
              url
              target
            }
            backgroundColor
            backgroundImage
            fontColor
            mediaRight
            sectionImage {
              node {
                ...MediaItemFragment
              }
            }
            sectionMap {
              title
              url
              target
            }
            photoGallery(first: 15) {
              nodes {
                ...MediaItemFragment
              }
            }
          }
          ... on FlexibleContentBlocksTabsRepeaterTabContentBlocksTextBlock {
            __typename
            sectionTitle
            sectionContent
            ctas {
              type
              link {
                title
                url
                target
              }
            }
            backgroundColor
            backgroundImage
            fontColor
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
