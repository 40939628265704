import * as React from "react";
import { SVGProps } from "react";
const Map = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 758.21 775.12"
    {...props}
  >
    <g data-name="Layer 1">
        <path fill-rule="evenodd" d="m391.22 575.53 159.67 199.59 159.67-199.59a217.433 217.433 0 0 0 47.65-135.83v-10.11c0-114.5-92.82-207.32-207.32-207.32s-207.32 92.82-207.32 207.32v10.11c0 49.37 16.8 97.27 47.65 135.83ZM550.9 498.7c38.17 0 69.11-30.94 69.11-69.11s-30.94-69.11-69.11-69.11-69.11 30.94-69.11 69.11 30.94 69.11 69.11 69.11Z"/>
        <path d="m0 523.83 194.74 92.61V92.61L0 0v523.83zm639.73-308.26V92.61L444.99 0v223.75c31.59-16.8 67.63-26.32 105.91-26.32 31.54 0 61.56 6.47 88.83 18.14Z"/>
        <circle cx="550.9" cy="429.59" r="52.97"/>
        <path d="M324.94 423.38c0-75.17 36.71-141.75 93.18-182.83V0L223.38 92.61v523.83l133.38-63.43a236.94 236.94 0 0 1-31.82-118.6v-11.02Z"/>
    </g>
  </svg>
);
export default Map;
