// import { gql } from "@apollo/client";

import {
    ConsentField,
    FieldError,
  } from "graphql";
  import useGravityForm, {
    ACTION_TYPES,
    FieldValue,
  } from "utilities/useGravityForm";
  
  interface Props {
    field: ConsentField;
    fieldErrors: FieldError[];
    formId?: number;
  }
  
  const DEFAULT_VALUE: ConsentField[] = [];
  
  export default function ConsentFieldField({ field, fieldErrors, formId }: Props) {
    const {
      databaseId: id,
      label,
      checkboxLabel,
      value,
      description,
      isRequired,
      labelPlacement,
      type,
      cssClass,
    } = field;
    const htmlId = `field_${formId}_${id}`;
    const { state, dispatch } = useGravityForm();
    const fieldValue = state.find(
      (fieldValue: FieldValue) => fieldValue.id === id,
    ) as ConsentField | undefined;

  
    return (
      <fieldset
        id={htmlId}
        className={`gfield gfield-${type} ${cssClass}`.trim()}
      >
        <legend className="font-heading text-gray-800 text-left">{label}</legend>
          <div className={`flex gap-2`}>
            <input
              type="checkbox"
              name={String(label)}
              id={`input_${formId}_${id}_${label}`}
              value={String(value)}
              required={Boolean(isRequired)}
              onChange={(event) => {
                dispatch({
                  type: ACTION_TYPES.updateConsentFieldValue,
                  fieldValue: {
                    id,
                    value: event.target.value,
                  },
                });
              }}
            />
            <label
              className="font-body text-gray-800"
              htmlFor={`input_${formId}_${id}_${label}`}
              style={{ display: 'inline-block !important' }}
            >
              {checkboxLabel}
            </label>
          </div>
        {description ? (
          <p className="field-description mt-4 text-gray-700">{description}</p>
        ) : null}
        {fieldErrors?.length
          ? fieldErrors.map((fieldError) => (
              <p key={fieldError.id} className="error-message">
                {fieldError.message}
              </p>
            ))
          : null}
      </fieldset>
    );
  }
  