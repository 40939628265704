import * as React from "react";
import { SVGProps } from "react";
const Exhibitors = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 97.33 80.15"
    {...props}
  >
    <g data-name="Layer 1">
      <path d="M59.22 72.23H16.8c-2.09 0-2.35-.22-2.36-2.27-.03-6.55 0-13.1 0-19.65 0-1.2.41-1.92 1.72-1.92 12.76.01 25.52 0 38.28.02.21 0 .42.07.79.15 0 1.07.01 2.12 0 3.17-.04 2.72 1.1 4.83 3.19 6.5.46.37.99 1 1 1.52.08 3.92.05 7.84.03 11.75 0 .21-.12.41-.23.73Z" />
      <path d="M86.74 63.63c1.25.12 2.23.2 3.22.31.92.1 1.84.22 2.89.35V4.51C63.37 8.45 33.99 8.47 4.48 4.5v59.75c1.99-.2 3.93-.41 6.09-.63 0 1.27.07 2.44-.04 3.58-.03.34-.54.84-.89.89-1.91.31-3.82.57-5.75.74-2.46.2-3.88-1.13-3.89-3.65C0 44.69 0 24.2.01 3.72.02 1.08 1.49-.23 4.2.03c7.03.7 14.04 1.58 21.08 2.15 13.84 1.12 27.7 1.3 41.55.32C75.41 1.9 83.98.9 92.54.06c3.5-.34 4.78.8 4.78 4.39v60.29c0 3-1.41 4.34-4.32 4.06-1.76-.17-3.51-.38-5.25-.69-.38-.07-.91-.61-.96-.98-.13-1.09-.05-2.21-.05-3.5Z" />
      <path d="M80.1 55.9v11.79c0 2.4 0 4.81-.01 7.22 0 2.94-1.09 4.61-3.29 5.1-2.19.49-3.74-.44-5.04-3.06-.08.05-.19.08-.23.15-1.37 2.56-2.96 3.45-5.13 2.89-2.04-.53-3.1-2.18-3.11-4.9V56.04c-3.59-1.01-4.26-1.9-4.26-5.63s.04-7.38.03-11.07c-.02-3.75 1.7-6.47 4.95-8.05 4.83-2.35 9.82-2.41 14.75-.31 3.71 1.58 5.67 4.45 5.62 8.64-.05 3.8 0 7.6 0 11.41 0 3.22-.86 4.25-4.27 4.9ZM27.44 44.62c-.5-3.65 1.03-6.62 4.37-8.15 4.4-2.01 8.9-2.01 13.27.1 3.26 1.57 4.69 4.44 4.14 8.05H27.43Zm51.37-23.76c-.04 3.95-3.19 7.1-7.09 7.09-4 0-7.13-3.21-7.11-7.26.03-3.88 3.26-7.14 7.1-7.14 3.94 0 7.14 3.29 7.1 7.31Zm-40.33-.64c3.55.04 6.5 3.12 6.43 6.73-.06 3.51-3.04 6.51-6.49 6.53-3.63.01-6.68-3.11-6.59-6.75.08-3.63 3.06-6.54 6.65-6.5Z" />
    </g>
  </svg>
);
export default Exhibitors;
