import Link from "next/link";
import { FeaturedImage } from "components/FeaturedImage";
import clsx from "clsx";
import { BgGraphic } from "components/BgGraphic";

const cometStar = {
  sourceUrl: "/svg-bgs/comet-star.svg",
  mediaDetails: { width: 414.03, height: 756.74 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const astronaut = {
  sourceUrl: "/svg-bgs/astronaut.svg",
  mediaDetails: { width: 515.54, height: 599.68 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface CelebGridProps {
  celebs: any[];
  tags: string | undefined;
}

const AllCelebGrid = ({ celebs, tags }: CelebGridProps) => {
  const filteredCelebs = tags
    ? celebs.filter((celeb: any) => {
        const celebTags = celeb.celebTags.nodes.map(
          (tag: { slug: string }) => tag.slug,
        );
        const tagsArray = tags.split(",");
        return tagsArray.some((tag) => celebTags.includes(tag));
      })
    : celebs;

  const hasCelebs = filteredCelebs.length > 0;

  const tagName = tags && tags.split(",").join(" & ");

  if (!hasCelebs && tags !== undefined) {
    return (
      <section className={`mb-xl`}>
        <p className="text-center">{`No ${tagName} found.`}</p>
      </section>
    );
  }

  const celebCount = filteredCelebs?.length ?? 0;

  let wrapperClass = clsx(`bg-white grid overflow-hidden relative`);

  let innerWrapperClass = clsx(
    `flex flex-col items-center justify-center container mx-auto px-4 md:px-8 py-6 z-10 relative h-full min-h-[284px]`,
  );

  let celebVariant = clsx(
    `justify-center items-center gap-4 w-full mdl:w-[75%] my-4 grid max-[320px]:grid-cols-1 grid-cols-2 mdl:grid-cols-4 z-10`,
    celebCount <= 7 && celebCount !== 4 && `flex flex-wrap`,
  );

  let celebItemClass = clsx(
    `group mx-auto`,
    celebCount <= 7 && celebCount !== 4 ? `max-w-[216px]` : `max-w-full`,
  );

  let celebItemImgClass = clsx(
    `w-full h-full grayscale group-hover:grayscale-0 transition duration-300 ease-in-out`,
  );

  let celebItemContentClass = clsx(
    `flex flex-col min-h-[68px] h-[110px] lg:h-[96px] items-center justify-center w-full text-center bg-primary group-hover:bg-primary-light p-2 text-slate transition duration-300 ease-in-out`,
  );

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  return (
    <section className={`${wrapperClass}`}>
      <div className={innerWrapperClass}>
        <div className={celebVariant}>
          {filteredCelebs.map((celeb: any) => {
            const {
              id,
              title,
              guestProps: { headshot, guestDays },
              uri,
              celebTags: { nodes: celebTags },
            } = celeb;

            let oneDate = guestDays && guestDays.length === 1;
              let formattedFirstDate = "";
              let formattedLastDateNoMonth = "";
              let formattedDateRange = "";

              if(guestDays) {
                oneDate = guestDays && guestDays.length === 1;
              const firstDate = guestDays && guestDays[0]?.guestDate;
              const lastDate =
                guestDays && guestDays[guestDays.length - 1]?.guestDate;

              const trimFirst = firstDate.split("T")[0];
              const [fyear, fmonth, fday] = trimFirst.split("-");
              const formFirst = `${fmonth}/${fday}/${fyear}`;
              const parseFirst = Date.parse(formFirst);
              formattedFirstDate = new Date(
                parseFirst,
              ).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
              });

              const trimLast = lastDate.split("T")[0];
              const [lyear, lmonth, lday] = trimLast.split("-");
              const formLast = `${lmonth}/${lday}/${lyear}`;
              const parseLast = Date.parse(formLast);
              // const formattedLastDateMonth = new Date(
              //   parseLast,
              // ).toLocaleDateString("en-US", {
              //   month: "long",
              //   day: "numeric",
              // });
              formattedLastDateNoMonth = new Date(
                parseLast,
              ).toLocaleDateString("en-US", {
                day: "numeric",
              });
              formattedDateRange = `${formattedFirstDate} - ${formattedLastDateNoMonth}`;
              }

            return (
              <div className={celebItemClass} key={id}>
                <Link href={uri}>
                  <div className={celebItemImgClass}>
                    <FeaturedImage className={``} image={headshot.node} />
                  </div>
                  <div className={celebItemContentClass}>
                    <h3 className={`text-xl`}>{title}</h3>
                    {guestDays && (
                      <>
                        {oneDate ? (
                          <p className={`mb-0 font-body`}>
                            {formattedFirstDate}
                          </p>
                        ) : (
                          <p className={`mb-0 font-body`}>
                            {formattedDateRange}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className={bgItemClass} style={{ gridArea: "1/1" }}>
          <BgGraphic bgType={"comet-left"} />
        </div>
      </div>
    </section>
  );
};

export default AllCelebGrid;
