import { gql } from "@apollo/client";
import { FlexibleContentBlocksHero, MediaItem } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import GuestStar from "public/guest-star";
import Exhibitors from "public/exhibitors";
import Tickets from "public/tickets";
import Map from "public/map";
import Link from "next/link";
import Image from "next/image";
import useMediaQuery from "utilities/useMediaQuery";
import { Carousel } from "components/Carousel";
import { Content } from "components/Content";
import { Button } from "components/Button";
interface HeroProps extends FlexibleContentBlocksHero {
  className?: string;
  image?: any;
  type?: string;
}

const Hero = ({
  className,
  heroType,
  type,
  hasOverlay,
  title,
  overlayCtas,
  hasGuestCards,
  guestCards,
  image,
  backgroundVideo,
  backgroundVideoMp4,
}: HeroProps) => {
  const isBasic =
    (heroType && heroType[0] === "basic-hero") || type === "basic-hero";
  const isImage =
    (heroType && heroType[0] === "img-hero") || type === "img-hero";
  const isVideo =
    (heroType && heroType[0] === "video-hero") || type === "video-hero";
  const backgroundImage = image?.node || null;
  const videoMimeType = backgroundVideo?.node?.mimeType || null;
  const videoUrl = backgroundVideo?.node?.mediaItemUrl || null;
  const mp4VideoMimeType = backgroundVideoMp4?.node?.mimeType || null;
  const mp4VideoUrl = backgroundVideoMp4?.node?.mediaItemUrl || null;
  const hasOverlayCtas = overlayCtas && overlayCtas.length > 0;

  const isMobile = useMediaQuery("(max-width: 868px)");

  const { altText, sourceUrl } = backgroundImage || {};
  const imageProps = {
    src: sourceUrl || "",
    alt: altText || "",
  };

  let wrapperClass = clsx(
    `grid bg-primary realtive overflow-hidden`,
    isImage && `content-center justify-center`,
    //  max-h-[530px]
  );

  let innerWrapperClass = clsx(
    `items-center justify-center mx-auto flex flex-col z-10 relative w-full h-full max-h-[530px]`,
    hasGuestCards ? `py-2 2xl:max-h-[730px]` : `py-6`,
    hasOverlay && `bg-black/[50%] mdl:min-h-[480px]`,
    // (isImage && !hasOverlay) && `content h-[530px]`,
  );

  let innerItemClass = clsx(
    `container flex flex-col gap-6 md:w-full`,
    hasOverlay && (hasGuestCards ? `justify-center w-[95%]` : `mdl:flex-row justify-center w-[95%]`),
  );

  let overlayClasses = clsx(
    `flex flex-col justify-center items-center gap-2`,
    hasOverlayCtas && (hasGuestCards ? `` : `mdl:w-[45%] mdl:gap-4 w-full pl-4 pr-4 mdl:pr-8 pb-6 mdl:pb-0`),
  );
  let overlayCtaClasses = clsx(
    `flex flex-row justify-center items-center my-auto gap-2 mdl:gap-4 w-full mb-2`,
    hasGuestCards ? `` : `mdl:w-[55%] mdl:border-l-4 border-slate/50 mdl:h-[80%]`,
  );

  let bgVideoClass = clsx(
    `relative w-auto h-full z-0 grid overflow-hidden max-h-[530px]`,
  );
  let bgPhotoClass = clsx(`relative w-[100vw] h-full z-0`);

  const renderImageSlide = (photoGallery: any, index: number) => {
    const { altText, sourceUrl } = photoGallery || {};
    const imageProps = {
      src: sourceUrl || "",
      alt: altText || "",
    };
    const imageClass = clsx("w-auto h-full mx-auto");
    return (
      <div
        key={index}
        className={`relative overflow-hidden max-w-full h-full max-h-[350px]`}
      >
        <Image
          className={imageClass}
          {...imageProps}
          // fill={true}
          loading="eager"
          // style={{ objectFit: "contain" }}
          width="1000"
          height="717"
        />
      </div>
    );
  };

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerWrapperClass} style={{ gridArea: "1/1" }}>
        <div className={innerItemClass}>
          {title && !hasOverlay && (
            <h1
              className={`font-heading uppercase text-4xl md:text-5xl font-bold text-center text-slate tracking-widest drop-shadow-primary ${
                !isBasic ? `hidden` : ``
              }`}
            >
              {title}
            </h1>
          )}
          {hasOverlay && (
            <div
              className={overlayClasses}
            >
              {hasGuestCards ? (
                <div className={`grid w-full max-w-[800px]`}>
                  <Carousel
                    items={guestCards?.nodes ?? []}
                    renderSlide={renderImageSlide}
                    numNgroups={1}
                    noDots
                  />
                </div>
              ) : (
                <>
                  {title && (
                    <h1
                      className={`font-heading uppercase text-[1.75rem] font-bold text-center text-secondary tracking-widest drop-shadow-primary`}
                    >
                      {title}
                    </h1>
                  )}
                  <Image
                    src={`/spacecon-base-logo.svg`}
                    alt={`SpaceCon Logo`}
                    width={400}
                    height={400}
                    className={`w-full h-auto max-w-[250px] mdl:max-w-[400px] my-3`}
                    priority
                  />
                  {/* <Button
                    href={`https://checkout.growtix.com/eh/SUPERHERO_COMIC_CON_2024?_gl=1*rrctmd*_ga*MTM1MjQ0ODI3LjE2Njg1NTM1NzQ.*_ga_WFJWZNSTPH*MTY5ODQzNjkzNS4yMzMuMS4xNjk4NDM3MDAwLjYwLjAuMA..`}
                    target={`_blank`}
                    type={`secondary`}
                  >
                    Buy Passes
                  </Button> */}
                  <div className={`text-center font-heading uppercase`}>
                    <p className={`text-white text-xl mdl:text-2xl mb-0`}>
                      Launch Date:
                    </p>
                    <p className={`text-secondary text-3xl mdl:text-4xl`}>
                      October 25 - 27, 2024
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          {hasOverlayCtas && !isMobile && (
            <div
              className={overlayCtaClasses}
            >
              {overlayCtas.map((cta, index) => {
                const { icon, link } = cta || {};
                const iconType = icon && icon[0];
                return (
                  <div key={index} className={`group h-fit`}>
                    <Link
                      href={link?.url ?? ""}
                      target={link?.target ?? "_self"}
                      className={`font-body font-bold text-center text-md mdl:text-lg uppercase tracking-widest flex justify-center items-center gap-2 text-slate group-hover:text-[#00AEEF] transition duration-300 ease-in-out ${hasGuestCards ? `flex-col mdl:flex-row max-w-[250px]` : `flex-col max-w-[150px]`}`}
                    >
                      {icon && (
                        <div
                          className={`flex flex-col justify-center items-center w-full ${hasGuestCards ? `h-[80px]` : `h-[100px]`}`}
                        >
                          {iconType === "guest-star" && (
                            <GuestStar
                              className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                            />
                          )}
                          {iconType === "exhibitors" && (
                            <Exhibitors
                              className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                            />
                          )}
                          {iconType === "tickets" && (
                            <Tickets
                              className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                            />
                          )}
                          {iconType === "map" && (
                            <Map
                              className={`m-auto w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                            />
                          )}
                          {/* <Image
                              src={`/${iconType}.svg`}
                              alt={`${iconType} icon`}
                              fillHover={`#00AEEF`}
                              width={150}
                              height={150}
                              className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px]`}
                              priority
                            /> */}
                        </div>
                      )}
                      {link?.title}
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {isImage && backgroundImage && hasOverlay && (
        <div className={bgPhotoClass} style={{ gridArea: "1/1" }}>
          {/* <FeaturedImage className={``} image={backgroundImage} /> */}
          <Image
            className={`w-full h-full`}
            {...imageProps}
            fill={true}
            loading="eager"
            style={{ objectFit: "cover" }}
          />
        </div>
      )}
      {isImage && backgroundImage && !hasOverlay && (
        <div className={`${bgPhotoClass} mx-auto`} style={{ gridArea: "1/1" }}>
          <FeaturedImage className={`flex justify-center`} image={backgroundImage} />
        </div>
      )}
      {isVideo && backgroundVideo && (
        <div className={`${bgVideoClass}`} style={{ gridArea: "1/1" }}>
          <video
            className={`place-self-center`}
            width={`auto`}
            height={`100%`}
            autoPlay
            playsInline
            muted
            loop
          >
            <source
              src={`${mp4VideoUrl}`}
              type={`${mp4VideoMimeType}`}
            ></source>
            <source src={`${videoUrl}`} type={`${videoMimeType}`}></source>
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {isMobile && hasOverlay && hasOverlayCtas && (
        <div className={`relative bg-primary content p-4`}>
          <div
            className={`flex max-[320px]:flex-wrap flex-row justify-center gap-2 md:gap-4 w-full mdl:w-[55%]`}
          >
            {overlayCtas.map((cta, index) => {
              const { icon, link } = cta || {};
              const iconType = icon && icon[0];
              return (
                <div key={index} className={`group`}>
                  <Link
                    href={link?.url ?? ""}
                    target={link?.target ?? "_self"}
                    className={`font-body font-bold text-center max-[320px]:text-sm text-md md:text-lg uppercase tracking-widest max-w-[150px] flex flex-col justify-center items-center gap-2 text-slate group-hover:text-[#00AEEF] transition duration-300 ease-in-out`}
                  >
                    {icon && (
                      <>
                        {iconType === "guest-star" && (
                          <GuestStar
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {iconType === "exhibitors" && (
                          <Exhibitors
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {iconType === "tickets" && (
                          <Tickets
                            className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px] fill-slate group-hover:fill-[#00AEEF] transition duration-300 ease-in-out`}
                          />
                        )}
                        {/* <Image
                              src={`/${iconType}.svg`}
                              alt={`${iconType} icon`}
                              fillHover={`#00AEEF`}
                              width={150}
                              height={150}
                              className={`mb-3 w-full h-auto max-w-[60px] md:max-w-[100px]`}
                              priority
                            /> */}
                      </>
                    )}
                    {link?.title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

Hero.fragments = {
  entry: gql`
    fragment HeroFragment on FlexibleContentBlocksHero {
      __typename
      heroType
      title
      hasOverlay
      hasGuestCards
      guestCards  {
        nodes {
          ...MediaItemFragment
        }
      }
      overlayCtas {
        icon
        link {
          title
          url
          target
        }
      }
      image: backgroundImage {
        node {
          ...MediaItemFragment
        }
      }
      backgroundVideo {
        node {
          altText
          mediaItemUrl
          mimeType
        }
      }
      backgroundVideoMp4 {
        node {
          altText
          mediaItemUrl
          mimeType
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
