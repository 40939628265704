import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { NavigationMenu } from "components/NavigationMenu";
import { useCycle, useScroll } from "framer-motion";
import { MediaItem, Menu, MenuItem, AcfGoogleMap, AcfLink } from "graphql";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import flatListToHierarchical from "utilities/flatListToHierarchical";
import useMediaQuery from "utilities/useMediaQuery";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";

const CountDownContainer = dynamic(
  () => import("./Fragments/CountDownContainer"),
  { ssr: false }
);

export interface HeaderProps {
  menuItems: MenuItem[];
  logo: MediaItem;
  ctaLink: AcfLink;
  countDownDate: string;
  dates: any[];
  locationName: string;
  // location: AcfGoogleMap;
  location: AcfLink;
  ticketsOnSale: boolean;
  generalTickets: AcfLink;
  isHomePage?: boolean;
}

const Header = ({
  menuItems,
  logo,
  ctaLink,
  countDownDate,
  dates,
  locationName,
  location,
  ticketsOnSale,
  generalTickets,
  isHomePage,
}: HeaderProps) => {
  const router = useRouter();
  const [open, cycleOpen] = useCycle(false, true);
  const [dropDown, cycleDrop] = useCycle(false, true);
  const ref = useRef<HTMLElement>(null);
  const { scrollY } = useScroll({
    target: ref,
  });
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const handleCopyRouting = (e: any) => {
    e.preventDefault();
    const routingNumber = e.target.textContent;
    navigator.clipboard.writeText(routingNumber);
    setIsCopied(true);
  };

  const logoSrc =
    router.query.logo === "v2"
      ? "/spacecon-test-logo1.png"
      : (logo?.sourceUrl ?? ``);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      // If latest is greater than height of header, set isScrolledPast to true
      if (latest > 0) {
        setIsScrolledPast(true);
      } else {
        setIsScrolledPast(false);
      }
    });
  }, [scrollY]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  const [isHeaderPast, setIsHeaderPast] = useState(false);
  useEffect(() => {
    return scrollY.onChange((latest) => {
      // If latest is greater than height of header, set isScrolledPast to true
      if (latest > 300) {
        setIsHeaderPast(true);
      } else {
        setIsHeaderPast(false);
      }
    });
  }, [scrollY]);

  const firstDate = dates && dates[0]?.showDate;
  const lastDate = dates && dates[dates.length - 1]?.showDate;

  const trimFirst = firstDate.split("T")[0];
  const [fyear, fmonth, fday] = trimFirst.split("-");
  const formFirst = `${fmonth}/${fday}/${fyear}`;
  const parseFirst = Date.parse(formFirst);
  const formattedFirstDate = new Date(parseFirst).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const trimLast = lastDate.split("T")[0];
  const [lyear, lmonth, lday] = trimLast.split("-");
  const formLast = `${lday}, ${lyear}`;
  const parseLast = Date.parse(formLast);
  const formattedLastDateNoMonth = new Date(parseLast).toLocaleDateString(
    "en-US",
    {
      day: "numeric",
      year: "numeric",
    }
  );
  const formattedDateRange = `${formattedFirstDate} - ${formLast}`;

  const today = new Date();
  const todayDay = new Date(today).getDate();
  const todayMonth = new Date(today).getMonth() + 1;
  const todayYear = new Date(today).getFullYear();
  const todayHour = new Date(today).getHours();
  const todayMinute = new Date(today).getMinutes();
  const todayDate = todayDay + "/" + todayMonth + "/" + todayYear;
  const formattedTodayDay = todayDate;
  const formattedTodayTime = todayHour + ":" + todayMinute;

  const filteredCountDownDate = countDownDate.split("T");
  let chunk = 1;
  const countDownDateDay = filteredCountDownDate
    .slice(0, chunk)
    .toLocaleString();
  const countDownDatetime = filteredCountDownDate
    .slice(chunk, chunk + 1)
    .toLocaleString();
  const formatCountDown = countDownDateDay.split("-").reverse().join("/");
  const formatCountDownTime = countDownDatetime.split("+");
  const formatedCountDownTime = formatCountDownTime
    .slice(0, chunk)
    .toLocaleString();
  const formatedCountDown = formatCountDown + " " + formatedCountDownTime;

  return (
    <header
      className={`${
        isScrolledPast ? `shadow-md` : ``
      } body-font sticky top-0 z-30 max-h-fit bg-primary font-sans text-gray-600 transition-shadow py-2 md:py-0`}
      ref={ref}
    >
      <div className="max-w-screen-2xl mx-auto grid grid-cols-4 md:flex md:flex-row gap-3 items-center justify-between px-4 md:px-8 pt-2 relative">
        {/* Dates & Location */}
        <div
          className={`max-[320px]:col-span-4 max-[320px]:items-center col-span-2 flex flex-col items-start justify-center text-left md:text-center text-slate order-3 md:order-1 md:w-[275px]`}
        >
          <div className={`w-[251px]`}></div>
          {/* <p
            className={
              `text-xl md:text-2xl lg:text-3xl font-heading uppercase tracking-widest mb-0` +
              // ` text-stroke-red`
              ` text-stroke-dark drop-shadow-yellow`
            }
          >
            {formattedDateRange}
          </p>
          {location && (
            <Link
              className={`flex flex-col flex-wrap max-[320px]:items-center items-start justify-center max-[320px]:text-center text-left md:text-center text-slate hover:text-secondary`}
              // href={`https://www.google.com/maps/search/?api=1&query=${location?.streetAddress}&query_place_id=${location?.placeId}`}
              href={location.url ?? ``}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p
                className={`max-[320px]:text-xs text-sm md:text-md font-body uppercase mb-0`}
              >
                {locationName}
              </p>
              <p className={`text-md md:text-lg font-body uppercase mb-0`}>
                San Antonio, TX
              </p>
            </Link>
          )} */}
        </div>
        {/* Logo */}
        <motion.div
          className={`col-span-3 flex flex-row flex-wrap items-center justify-center order-1 md:order-2`}
        >
          {logoSrc && (
            <Link href="/">
              <Image
                src={logoSrc}
                alt="logo"
                width={300}
                height={300}
                className={`ml-24 md:ml-0 max-[320px]:mb-2 mb-6 max-[320px]:w-40 w-40 h-full sm:w-48 lg:w-52`}
              />
            </Link>
          )}
        </motion.div>
        {/* Countdown */}
        <div
          className={`hidden md:flex flex-row flex-wrap items-center justify-center md:justify-end order-5 md:order-3 mt-[2px] mb-2 md:w-[275px]`}
        >
          {/* {countDownDate &&
          formattedTodayDay !== countDownDateDay &&
          formattedTodayTime !== countDownDatetime ? (
            <CountDownContainer date={formatedCountDown} />
          ) : (
            <div className={`w-[213px]`}></div>
          )} */}
          <div className={`w-[213px]`}></div>
        </div>
        <div
          className={`col-span-1 flex md:hidden w-[30px] h-[30px] relative mr-0 ml-auto order-2 md:order-4`}
        >
          <div
            className={`flex flex-row flex-wrap items-center justify-center max-w-[100vw] bg-primary absolute -right-4 ${
              open ? `top-14` : `top-0`
            }`}
          >
            {/* Navigation Menu */}
            <NavigationMenu
              menuItems={flatListToHierarchical(menuItems)}
              isOpen={open}
              isDropOpen={dropDown}
              isHeaderPast={isHeaderPast}
              type="secondary"
              toggleOpen={() => cycleOpen()}
              toggleDrop={() => cycleDrop()}
              formattedDateRange={formattedDateRange}
              locationName={locationName}
              location={location}
              ctaLink={ctaLink}
              ticketsOnSale={ticketsOnSale}
              generalTickets={generalTickets}
            />
          </div>
        </div>
        {ticketsOnSale ? (
          <>
            {generalTickets && (
              <div
                className={`max-[320px]:mr-auto mr-0 ml-auto order-4 max-[320px]:col-span-4 col-span-2 md:hidden`}
              >
                <Link
                  className={`flex text-center rounded-md bg-secondary border-2 border-secondary py-2 px-4 text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                  href={generalTickets.url ?? ``}
                  target="_blank"
                >
                  {generalTickets.title}
                </Link>
              </div>
            )}
          </>
        ) : (
          <>
            {ctaLink && (
              <div
                className={`max-[320px]:mr-auto mr-0 ml-auto order-4 max-[320px]:col-span-4 col-span-2 md:hidden`}
              >
                <Link
                  className={`flex text-center rounded-md bg-secondary border-2 border-secondary py-2 px-4 text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                  href={ctaLink.url ?? ``}
                >
                  {ctaLink.title}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
      {/* CTA */}
      <div className={`bg-slate`}>
        <div
          className={`hidden md:flex flex-row items-center justify-between max-w-screen-2xl px-4 md:px-8 py-1 mx-auto`}
        >
          {/* Navigation Menu */}
          <NavigationMenu
            menuItems={flatListToHierarchical(menuItems)}
            isOpen={open}
            isDropOpen={dropDown}
            type="secondary"
            toggleOpen={() => cycleOpen()}
            toggleDrop={() => cycleDrop()}
            ticketsOnSale={ticketsOnSale}
            generalTickets={generalTickets}
          />
          {ticketsOnSale ? (
            <>
              {generalTickets && (
                <Link
                  className={`rounded-md whitespace-nowrap bg-secondary border-2 border-secondary py-2 px-4 text-sm md:text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                  href={generalTickets.url ?? ``}
                  target="_blank"
                >
                  {generalTickets.title}
                </Link>
              )}
            </>
          ) : (
            <>
              {ctaLink && (
                <Link
                  className={`rounded-md bg-secondary border-2 border-secondary py-2 px-4 text-sm md:text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                  href={ctaLink.url ?? ``}
                >
                  {ctaLink.title}
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
