import { AcfLink, FlexibleContentBlocksLogoGrid } from "graphql";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";

interface LogoItemProps extends FlexibleContentBlocksLogoGrid {
  className?: string;
  variant?: any;
  logoNode?: any;
  logoContainer?: any;
  logoClass?: any;
  link?: AcfLink | null;
}

const LogoItem = ({
  className,
  variant,
  logoNode,
  logoContainer,
  logoClass,
  link,
}: LogoItemProps) => {
  const isCarousel = variant === "carousel";
  const isGrid = variant === "grid";

  return (
    <>
      {isCarousel &&
        (link ? (
          <Link
            href={link?.url ?? ""}
            target={link?.target ?? "_self"}
            className={logoContainer}
          >
            <div className={logoClass}>
              <FeaturedImage
                image={logoNode}
                className={`max-h-[120px] h-full w-full`}
                imgClassName={`h-full w-full max-h-[120px]`}
                svgWidth={`100%`}
              />
            </div>
          </Link>
        ) : (
          <div className={logoContainer}>
            <div className={logoClass}>
              <FeaturedImage
                image={logoNode}
                className={`max-h-[120px] h-full w-full`}
                imgClassName={`h-full w-full max-h-[120px]`}
                svgHeight={`80px`}
              />
            </div>
          </div>
        ))}
      {isGrid &&
        (link ? (
          <Link href={link?.url ?? ""} target={link?.target ?? "_self"}>
            <div className={logoClass}>
              <FeaturedImage
                image={logoNode}
                className={`max-h-[120px] h-full w-full`}
                imgClassName={`h-full w-full max-h-[120px]`}
                svgHeight={`80px`}
              />
            </div>
          </Link>
        ) : (
          <div className={logoClass}>
            <FeaturedImage
              image={logoNode}
              className={`max-h-[120px] h-full w-full`}
              imgClassName={`h-full w-full max-h-[100px]`}
              svgHeight={`80px`}
            />
          </div>
        ))}
    </>
  );
};

export default LogoItem;
