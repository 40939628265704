import * as React from "react";
import { SVGProps } from "react";
const Tickets = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 109.91 100.75"
    {...props}
  >
    <g data-name="Layer 1">
      <path d="M68.96 39.12c.51.92.86 1.66 1.34 2.31.07.1 1.04-.21 1.04-.33 0-.83-.14-1.67-.25-2.61 6.31-1.86 12.95-3.82 19.51-5.75.77 1.27 1.37 2.57 2.24 3.65 2.16 2.65 5.03 3.96 8.47 3.88.77-.02 1.07.22 1.26.93.42 1.62.93 3.22 1.39 4.81-4.25 3.19-5.52 6.04-4.27 9.53 1.25 3.51 4.1 5.21 8.58 5.06.57 1.89 1.14 3.82 1.64 5.47-1.41 1.41-2.86 2.57-3.94 4-1.79 2.38-2.26 5.16-1.62 8.07.14.62.03.91-.59 1.09-6.4 1.87-12.8 3.76-19.18 5.64-.11-.6-.08-1.25-.36-1.72-.21-.36-.82-.48-1.24-.71-.22.43-.64.86-.62 1.28.02.52.4 1.03.66 1.62-17.36 5.12-34.76 10.25-52.27 15.41-2.05-5.2-5.83-7.81-11.53-7.58-.57-1.91-1.14-3.81-1.72-5.78 3.82-2.1 5.55-5.23 4.26-9.54-1-3.34-3.84-4.94-8.57-5.09-.53-1.76-1.09-3.54-1.59-5.33-.05-.19.16-.57.36-.7 4.47-2.84 6.12-6.86 5.09-12.02-.01-.07.03-.14.06-.32 17.37-5.12 34.78-10.25 51.86-15.28Zm11.67 40.37c.71.72 1.21 1.23 1.72 1.75.34-.35.88-.66.97-1.07.18-.84-.61-1.87-1.24-1.72-.42.1-.77.54-1.45 1.04Zm1.95-3.33c-.67-.71-1.11-1.25-1.66-1.67-.06-.05-.89.56-.98.96-.2.8.63 1.92 1.22 1.76.43-.12.77-.55 1.43-1.06Zm-6.2-19.63c-.3-1.04-.36-1.97-1.4-1.86-.35.04-.91.69-.9 1.03.04.71.17 1.67 1.19 1.62.41-.02.79-.56 1.1-.8Zm-.83-4.26c-.7-.66-1.17-1.18-1.73-1.55-.07-.05-.85.6-.91.99-.15.93.6 1.86 1.26 1.67.42-.12.74-.58 1.38-1.11Zm-4.28-4.46c.69.68 1.18 1.17 1.67 1.65.34-.35.9-.66.97-1.06.16-.88-.65-1.89-1.26-1.7-.42.13-.74.58-1.38 1.11Zm-1.24-3.93c.79.69 1.3 1.15 1.82 1.6.32-.37.91-.75.89-1.11-.03-.72-.2-1.67-1.21-1.63-.41.02-.79.58-1.5 1.14Zm7.88 16.41c-.69-.72-1.17-1.21-1.65-1.71-.35.38-.98.75-.98 1.12 0 .72.19 1.65 1.21 1.6.39-.02.76-.52 1.42-1.01Zm1.16 3.97-1.63-1.7c-.35.37-.98.73-.99 1.12-.02.73.22 1.64 1.21 1.63.38 0 .75-.54 1.41-1.05Zm1.26 3.97c-.77-.75-1.26-1.22-1.75-1.7-.34.35-.96.72-.95 1.06.02.71.1 1.69 1.14 1.68.41 0 .82-.52 1.57-1.04Zm1.11 3.89c-.72-.67-1.17-1.19-1.74-1.55-.09-.06-.87.59-.94.99-.16.9.61 1.83 1.29 1.66.41-.11.73-.56 1.38-1.1Z" />
      <path d="M32.05 19.14c.82.44 1.51.89 2.26 1.17.14.05.8-.71.74-.78-.46-.7-1.03-1.33-1.7-2.15C37.22 12.1 41.31 6.52 45.39.96c4.87 2.95 9.41 2.63 13.78-.95 1.63 1.19 3.26 2.38 4.88 3.56-1.71 4.45-.93 7.85 2.28 10.08 3.08 2.15 6.64 1.76 9.94-1.15 1.62 1.18 3.25 2.36 4.92 3.57-2.08 5.23-1.02 9.69 3.27 13.39-.79 1.09-1.52 2.16-2.33 3.17-.24.3-.66.51-1.04.62-6.1 1.82-12.19 3.63-18.31 5.4-.31.09-.74-.2-1.11-.32-.06-.02-.1-.12-.16-.17-.57-.42-1.17-1.18-1.86-.43-.33.36-.21 1.14-.32 1.96-14.73 4.34-29.86 8.8-45.09 13.3 2.17 5.93.6 10.29-5.39 12.88.93 3.16 1.86 6.29 2.82 9.55.58-.11 1.09-.2 1.6-.3 3.59-.72 6.53 1.51 6.68 5.26-1.64-1.78-3.48-2.92-5.84-2.99-2.33-.06-4.26.82-5.91 2.51-1.66-1.21-3.29-2.4-4.93-3.61 2.12-5.23 1-9.63-3.28-13.39 10.73-14.64 21.47-29.31 32.05-43.76Zm8.06 3.16c-.47.73-1.03 1.38-.93 1.5.45.58 1.06 1.05 1.69 1.43.08.05.96-.86.89-.98-.37-.63-.93-1.15-1.64-1.95Zm14.04 13.22c.51-.73 1.09-1.37 1.01-1.47-.47-.57-1.07-1.05-1.7-1.44-.08-.05-.92.77-.85.89.34.63.86 1.17 1.54 2.01Zm-8.85-8.63c0-.17.02-.33.03-.5-.63-.39-1.22-.87-1.91-1.1-.18-.06-.63.66-.95 1.03.55.47 1.06 1.04 1.69 1.35.21.1.75-.5 1.14-.78Zm11.63 7.65c-.51.72-1.11 1.34-1.01 1.47.42.58.98 1.08 1.59 1.45.12.07 1.03-.8.98-.9-.35-.63-.87-1.18-1.56-2.02Zm-10.01-7.32c-.52.71-1.1 1.29-1.01 1.43.39.58.93 1.11 1.53 1.48.12.08 1.07-.78 1.03-.86-.35-.63-.86-1.18-1.54-2.05Zm5.06 4.61.04-.5c-.61-.41-1.19-.92-1.87-1.17-.18-.07-.65.66-.99 1.01.55.47 1.04 1.05 1.67 1.36.21.11.76-.45 1.15-.71ZM36.79 19.88c-.48.74-1.04 1.35-.94 1.49.42.57.99 1.06 1.61 1.41.12.07 1.02-.82.97-.91-.38-.63-.91-1.16-1.63-1.99Z" />
    </g>
  </svg>
);
export default Tickets;
