import { gql } from "@apollo/client";

export const SITE_SETTINGS_FRAGMENT = gql`
  fragment SiteSettingsFragment on SiteSettings {
    siteSettingFields {
      ctaLink {
        target
        title
        url
      }
      logo {
        node {
          ...MediaItemFragment
        }
      }
      locationName
      countdownDate
      showDates {
        showDate
      }
      ticketsOnSale
      generalAdmissionTickets {
        target
        title
        url
      }
      autographTickets {
        target
        title
        url
      }
      photoOpTickets {
        target
        title
        url
      }
      location {
        url
        target
        title
      }
      socialLinks {
        facebookUrl {
          target
          title
          url
        }
        instagramUrl {
          target
          title
          url
        }
        twitterUrl {
          target
          title
          url
        }
        youtubeUrl {
          target
          title
          url
        }
      }
    }
  }
`;
