import clsx from "clsx";
import { Content } from "components/Content";
import { FlexibleContentBlocksTextImage } from "graphql";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";

const googleBadge = {
  sourceUrl: "/google-play-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Google Play Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};
const appleBadge = {
  sourceUrl: "/apple-store-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Apple Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};

interface LetterBlockProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const LetterBlock = ({
  className,
  sectionTitle,
  sectionContent,
  afterImageContent,
  signatureName,
  writersPosition,
  fontColor,
  mediaRight,
  sectionImage,
}: LetterBlockProps) => {
  const sideImg = sectionImage && sectionImage?.node;

  const isMobile = useMediaQuery("(max-width: 768px)");

  let innerSectionClass = clsx(
    `flex flex-col md:flex-wrap items-center justify-between mx-auto gap-6 relative h-full max-w-screen-2xl px-4 md:px-8 py-7 relative z-10`,
    mediaRight ? `mdl:flex-row` : `mdl:flex-row-reverse`,
  );

  let innerSectionTitleClass = clsx(`w-full order-1`);
  let innerContentTitleClass = clsx(
    `font-heading text-4xl md:text-[3.5rem] leading-none text-center font-bold tracking-widest text-${fontColor}`,
  );

  let secContentClass = clsx(
    `w-full mdl:w-[58%] font-body text-md tracking-wider mt-4 md:mb-0 text-${fontColor} order-3 mdl:order-2`,
  );

  let sideMediaClass = clsx(`w-full mdl:w-[38%] order-2 mdl:order-3`);

  let afterContentClass = clsx(
    `grid w-full order-4 font-body text-md tracking-wider text-${fontColor} -mt-6 mdl:mt-0`,
  );
  let signatureClass = clsx(
    `flex flex-col justify-center pr-8 mdl:pr-12 w-fit`,
    mediaRight ? `justify-self-start` : `justify-self-end`,
  );

  return (
    <div className={`${className} ${innerSectionClass}`}>
      {sectionTitle && (
        <div className={innerSectionTitleClass}>
          {/* <h3 className={innerContentTitleClass}>{sectionTitle}</h3> */}
          <Content className={innerContentTitleClass} content={sectionTitle} />
        </div>
      )}

      {sectionContent && (
        <Content className={secContentClass} content={sectionContent} />
      )}
      {sectionImage && (
        <div className={sideMediaClass}>
          <FeaturedImage className={``} image={sideImg} />
        </div>
      )}
      {(afterImageContent || signatureName || writersPosition) && (
        <div className={afterContentClass}>
          {afterImageContent && (
            <Content className={``} content={afterImageContent} />
          )}
          {(signatureName || writersPosition) && (
            <div className={signatureClass}>
              {signatureName && (
                <h4
                  className={`font-sign text-4xl md:text-5xl text-center font-bold tracking-widest text-${fontColor} transform -rotate-[5deg] mt-0 mb-6`}
                >
                  {signatureName}
                </h4>
              )}
              {writersPosition && (
                <p
                  className={`font-body text-md md:text-xl text-center font-bold tracking-wider text-${fontColor}`}
                >
                  {writersPosition}
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LetterBlock;
