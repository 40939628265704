import { gql } from "@apollo/client";
import { FlexibleContentBlocksLogoGrid } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";
import LogoItem from "./Fragments/LogoItem";
import { Content } from "components/Content";
import useMediaQuery from "utilities/useMediaQuery";
// import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
interface LogosProps extends FlexibleContentBlocksLogoGrid {
  className?: string;
  image?: any;
}

const Logos = ({
  className,
  variant,
  sectionTitle,
  backgroundColor,
  featuredLogo,
  featuredLogoLink,
  logos,
}: LogosProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isCarousel = variant && variant[0] === "carousel";
  const isGrid = variant && variant[0] === "grid";
  const isTrans = backgroundColor && backgroundColor[0] === "transparent";

  const featuredLogoImg = featuredLogo?.node ?? null;

  const logosCount = logos?.length ?? 0;

  const var1 = logosCount * 120;
  const var2 = var1 / 1.5;
  const var3 = var2 - 120; // start varible
  const var4 = var3 / 1.7;
  const var5 = var4 + 85; // end variable

  // console.log(
  //   "var1",
  //   var1,
  //   "var2",
  //   var2,
  //   "var3",
  //   var3,
  //   "var4",
  //   var4,
  //   "var5",
  //   var5,
  // );

  const marqueeVariants = {
    animate: {
      // x: [1275, -760],
      x: [var3, -var5],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 50,
          ease: "linear",
        },
      },
    },
  };

  let sectionClass = clsx(
    `bg-${backgroundColor} pb-8`,
    !isTrans && `border-primary-light border-y-8`,
  );

  let wrapperClass = clsx(
    `flex flex-col items-center justify-between mx-auto gap-6 relative h-full max-w-screen-2xl overflow-x-hidden`,
    !isTrans ? `pt-5` : ``,
  );
  let secTitleClass = clsx(
    `font-body text-3xl text-center font-bold tracking-wider uppercase py-2`,
    !isTrans ? `text-slate` : `text-primary`,
  );

  let marqueeStyles = clsx(`flex flex-row justify-start items-center`);
  let logoContainer = clsx(
    `flex items-center justify-center h-[120px] w-[120px]`,
  );

  let logoGridClass = clsx(
    // `grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4`,
    `flex flex-row flex-wrap justify-center items-center gap-4`,
  );
  let gridLogoClass = clsx(
    `flex items-center justify-center p-2 max-w-[120px] max-h-[120px]`,
  );

  let logoClass = clsx(
    `relative p-2 max-w-[120px] max-h-[120px] flex items-center justify-center`,
  );
  let featLogoClass = clsx();

  return (
    <section className={sectionClass}>
      {!isTrans && sectionTitle && (
        <div className={`bg-primary-light py-2 px-2`}>
          <h3 className={secTitleClass}>{sectionTitle}</h3>
        </div>
      )}
      <div className={wrapperClass}>
        {isTrans && sectionTitle && (
          <h3 className={secTitleClass}>{sectionTitle}</h3>
        )}
        {featuredLogo &&
          (featuredLogoLink ? (
            <Link
              href={featuredLogoLink?.url ?? ""}
              target={featuredLogoLink?.target ?? "_self"}
              className={``}
            >
              <div className={featLogoClass}>
                <FeaturedImage
                  image={featuredLogoImg}
                  className={`max-h-[100px] h-full w-auto`}
                  imgClassName={`h-full w-auto max-h-[100px]`}
                  svgWidth={`100%`}
                />
              </div>
            </Link>
          ) : (
            <div className={featLogoClass}>
              <FeaturedImage
                image={featuredLogoImg}
                className={`max-h-[100px] h-full w-auto`}
                imgClassName={`h-full w-auto max-h-[100px]`}
                svgWidth={`100%`}
              />
            </div>
          ))}
        {logos && isCarousel && (
          <motion.div
            className={`${marqueeStyles} track`}
            variants={marqueeVariants}
            animate="animate"
          >
            {logos.map((logo, index) => {
              const hasLink = logo?.itemLink?.url ?? null;
              const link = hasLink ? logo?.itemLink : null;
              const logoNode = logo?.logo?.node ?? null;
              const fileUrl = logoNode?.sourceUrl;

              return (
                <LogoItem
                  key={`${index}-${logo}`}
                  variant={"carousel"}
                  logoNode={logoNode}
                  logoContainer={logoContainer}
                  logoClass={logoClass}
                  link={link ?? null}
                />
              );
            })}
            {logos.map((logo, index) => {
              const hasLink = logo?.itemLink?.url ?? null;
              const link = hasLink ? logo?.itemLink : null;
              const logoNode = logo?.logo?.node ?? null;
              const fileUrl = logoNode?.sourceUrl;

              return (
                <LogoItem
                  key={`${index}-${logo}`}
                  variant={"carousel"}
                  logoNode={logoNode}
                  logoContainer={logoContainer}
                  logoClass={logoClass}
                  link={link ?? null}
                />
              );
            })}
          </motion.div>
        )}
        {logos && isGrid && (
          <div className={logoGridClass}>
            {logos.map((logo, index) => {
              const hasLink = logo?.itemLink?.url ?? null;
              const link = hasLink ? logo?.itemLink : null;
              const logoNode = logo?.logo?.node ?? null;

              return (
                <LogoItem
                  key={`${index}-${logo}`}
                  variant={"grid"}
                  logoNode={logoNode}
                  logoContainer={logoContainer}
                  logoClass={gridLogoClass}
                  link={link ?? null}
                />
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default Logos;

Logos.fragments = {
  entry: gql`
    fragment LogosFragment on FlexibleContentBlocksLogoGrid {
      __typename
      variant
      sectionTitle
      backgroundColor
      featuredLogo {
        node {
          ...MediaItemFragment
        }
      }
      featuredLogoLink {
        title
        url
        target
      }
      logos {
        logo {
          node {
            ...MediaItemFragment
          }
        }
        itemLink {
          title
          url
          target
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
