import category from "./category";
import tag from "./tag";
import frontPage from "./front-page";
import page from "./page";
import single from "./single";
import singleCelebrity from "./single-celebrity";
// // Taxonomies
import taxCeleb from "./taxonomy-celebrity-category";
// // Archives
import archivePosts from "./archive-post";

export default {
  // category: category,
  // tag,
  "front-page": frontPage,
  page,
  // // Post types
  // single,
  "single-celebrity": singleCelebrity,
  // // Taxonomies
  "taxonomy-celebrity-category": taxCeleb,
  // // Archives
  "archive-post": archivePosts,
};
