import { FlexibleContentBlocksTabsLayout } from "graphql";

import clsx from "clsx";
import { SplitContent, TextImage, TextBlock, FAQ } from "components";

interface TabsBlockProps extends FlexibleContentBlocksTabsLayout {
  keyName?: string;
  blocks: any;
}

const TabContent = ({ blocks, keyName }: TabsBlockProps) => {
  return (
    <>
      {blocks &&
        blocks.map((block: any, index: number) => {
          const prefix =
            "FlexibleContentBlocksTabsRepeaterTabContentBlocks";

          // @ts-ignore
          const { fieldGroupName } = block;

          let name = fieldGroupName && fieldGroupName.replace(prefix, "");

          switch (name) {
            // Hero
            case "SplitContent": {
              return (
                <SplitContent
                  key={`${keyName}-${index}`}
                  sectionTitle={block?.sectionTitle}
                  sideOneTitle={block?.sideOneTitle}
                  sideOneContent={block?.sideOneContent}
                  sideTwoTitle={block?.sideTwoTitle}
                  sideTwoContent={block?.sideTwoContent}
                  sideOneImage={block?.sideOneImage}
                  sideTwoImage={block?.sideTwoImage}
                />
              );
            }
            // Text Image
            case "TextImage": {
              return (
                <TextImage
                  key={`${keyName}-${index}`}
                  ankerLinkId={block?.ankerLinkId}
                  variant={block?.variant}
                  sectionTitle={block?.sectionTitle}
                  sectionContent={block?.sectionContent}
                  hasAppLinks={block?.hasAppLinks}
                  ctaRepeater={block?.ctaRepeater}
                  appleAppLink={block?.appleAppLink}
                  googleAppLink={block?.googleAppLink}
                  backgroundColor={block?.backgroundColor}
                  backgroundImage={block?.backgroundImage}
                  fontColor={block?.fontColor}
                  mediaRight={block?.mediaRight}
                  sectionImage={block?.sectionImage}
                  sectionMap={block?.sectionMap}
                  photoGallery={block?.photoGallery}
                  afterImageContent={block?.afterImageContent}
                  signatureName={block?.signatureName}
                  writersPosition={block?.writersPosition}
                />
              );
            }
            // Text Block
            case "TextBlock": {
              return (
                <TextBlock
                  key={`${keyName}-${index}`}
                  sectionTitle={block?.sectionTitle}
                  sectionContent={block?.sectionContent}
                  ctas={block?.ctas}
                  backgroundColor={block?.backgroundColor}
                  backgroundImage={block?.backgroundImage}
                  fontColor={block?.fontColor}
                />
              );
            }
            // Faq
            case "Faq": {
              return (
                <FAQ
                  key={`${keyName}-${index}`}
                  title={block?.title}
                  content={block?.content}
                  hasSideImage={block?.hasSideImage}
                  sideImage={block?.sideImage}
                  items={block?.items}
                />
              );
            }

            default: {
              return null;
            }
          }
        })}
    </>
  );
};

export default TabContent;
