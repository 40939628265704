import * as React from "react";
import { SVGProps } from "react";
const GuestStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 111.06 105.32"
    {...props}
  >
    <g data-name="Layer 1">
      <path d="m.18 40.16 8.84-1.29c9.29-1.34 18.57-2.7 27.86-4.01 1.05-.15 1.6-.59 2.06-1.52C44.26 22.51 49.62 11.7 54.96.89c.12-.25.26-.49.46-.89.34.62.64 1.12.9 1.64 5.23 10.56 10.47 21.12 15.65 31.7.47.96 1.02 1.37 2.05 1.51 11.23 1.59 22.46 3.23 33.68 4.86 1 .15 2 .31 3.01.47l.35.5c-.44.26-.95.45-1.31.8-8.54 8.29-17.07 16.6-25.61 24.89-.62.6-.87 1.12-.71 2.05 2.12 12.02 4.17 24.05 6.23 36.08.04.21 0 .43 0 .82-.61-.31-1.11-.54-1.59-.8-10.49-5.5-20.99-11-31.47-16.53-.84-.44-1.47-.46-2.31-.01a9493.96 9493.96 0 0 1-31.59 16.6c-.44.23-.9.43-1.58.75.5-2.97.95-5.72 1.42-8.46 1.63-9.46 3.26-18.91 4.91-28.36.16-.91-.04-1.54-.73-2.21-8.56-8.27-17.09-16.58-25.63-24.87-.33-.32-.73-.58-1.1-.86.06-.14.12-.27.17-.41Zm55.24 37.41c10.08.03 18.31-8.1 18.37-18.13a18.356 18.356 0 0 0-18.31-18.51c-10.04-.05-18.38 8.25-18.41 18.31-.03 10.07 8.21 18.3 18.35 18.34Z" />
      <path d="M55.75 68.58c-2.72-.6-5.4-1.16-8.05-1.82-.36-.09-.8-.74-.83-1.15-.2-3.05.5-4.03 3.44-5.02 3.39-1.14 3.56-1.23 2.03-4.48-.92-1.94-.63-3.68-.15-5.5.47-1.77 1.91-2.77 3.61-2.74 1.68.04 2.82 1.05 3.49 2.74 1 2.52.39 4.74-.87 6.96-.86 1.51-.62 1.99 1.03 2.52.98.31 1.97.56 2.93.91 1.8.65 3.01 3.69 2.09 5.3-.16.27-.57.49-.91.56-2.57.58-5.16 1.13-7.83 1.71Z" />
    </g>
  </svg>
);
export default GuestStar;
