import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import { useEffect, useId, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Content } from "components/Content";

interface AccordionProps {
  question?: string;
  answer?: string;
  index?: number;
  children?: any;
  isFilter?: boolean;
  filterClass?: string;
}

const Accordion = ({
  question,
  answer,
  index,
  children,
  isFilter = false,
  filterClass,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();

  useEffect(() => {
    if (index === 0) {
      setIsOpen(true);
    }
  }, [index]);

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className={`${
          isFilter ? filterClass : null
        } w-full max-w-screen-2xl mx-auto`}
      >
        <AnimatePresence>
          <m.div
            className={`w-full flex items-center justify-between cursor-pointer p-4 rounded-md my-2 ${
              isOpen ? `bg-primary-light` : `bg-primary`
            }`}
            onTap={() => setIsOpen(!isOpen)}
          >
            <h3 className={`text-slate text-xl`}>{question}</h3>
            <m.div key="question" className={`icon flex items-center p-2 mx-2`}>
              {isOpen ? (
                <FaAngleUp className={`fill-slate`} />
              ) : (
                <FaAngleDown className={`fill-slate`} />
              )}
            </m.div>
          </m.div>

          {isOpen && (
            <m.div
              key={id}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              className={`answer flex mb-4 p-4`}
              exit={{ opacity: 0 }}
            >
              <Content className={`text-lg font-body`} content={answer} />
              {children}
            </m.div>
          )}
        </AnimatePresence>
      </m.div>
    </LazyMotion>
  );
};

export default Accordion;
