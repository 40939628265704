import { useQuery, gql } from "@apollo/client";
import * as MENUS from "constants/menus";
import { Layout, Blocks } from "features";
import { NavigationMenu, Form, Hero } from "components";
import {
  BLOG_INFO_FRAGMENT,
  SITE_SETTINGS_FRAGMENT,
  SEO_FRAGMENT,
} from "fragments";

const Component = (props) => {
  const { data, loading, error } = props;

  if (loading) {
    return <Loading type="page" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { page, headerMenuItems, footerMenuItems, siteSettings } = data;
  const { seo, title, flexibleContent } = page;
  const { blocks } = flexibleContent;
  const {
    location,
    locationName,
    logo,
    ctaLink,
    socialLinks,
    showDates,
    countdownDate,
    ticketsOnSale,
    generalAdmissionTickets,
  } = siteSettings.siteSettingFields;
  const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = socialLinks;

  return (
    <Layout
      headerMenuItems={headerMenuItems}
      footerMenuItems={footerMenuItems}
      siteSettings={siteSettings}
      seo={seo}
      logo={logo.node}
      ctaLink={ctaLink}
      countDownDate={countdownDate}
      dates={showDates}
      locationName={locationName}
      location={location}
      facebookUrl={facebookUrl}
      instagramUrl={instagramUrl}
      twitterUrl={twitterUrl}
      youtubeUrl={youtubeUrl}
      ticketsOnSale={ticketsOnSale}
      generalTickets={generalAdmissionTickets}
    >
      {blocks ? (
        <Blocks blocks={blocks} />
      ) : (
        <Hero heroType={`basic`} title={title} />
      )}
      <Form
        className={``}
        sectionId={`newsletter`}
        title={`GET THE LATEST SHOW UPDATES`}
        form={`2`}
        bgImage={`radar-spaceshuttle`}
        backgroundColor={`white`}
      />
    </Layout>
  );
};

Component.query = gql`
  query PageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum!
    $footerLocation: MenuLocationEnum!
    $asPreview: Boolean = false
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      title
      content
      seo {
        ...SEOFragment
      }
      flexibleContent {
        ...BlocksFragment
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    siteSettings {
      ...SiteSettingsFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation }
      first: 50
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
  }
  ${NavigationMenu.fragments.entry}
  ${SEO_FRAGMENT}
  ${BLOG_INFO_FRAGMENT}
  ${SITE_SETTINGS_FRAGMENT}
  ${Blocks.fragments.entry}
`;

export default Component;

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    footerLocation: MENUS.FOOTER_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
