import dynamic from "next/dynamic";
// Hero: (Non dynamic import) Above the fold content
import {
  Hero,
  FeaturesBlock,
  CTABlock,
  SplitContent,
  Guests,
  Logos,
  TextImage,
  TextBlock,
  TabsBlock,
  FAQ,
} from "components";
// Dynamic Imports: Below the fold content
import { FORM_BLOCK_FRAGMENT } from "components/Form/Form";
const Form = dynamic(() => import("components/Form/Form"), {
  ssr: true,
});

import {
  FlexibleContent,
  FlexibleContentBlocks_Layout,
  FlexibleContentBlocksHero,
  FlexibleContentBlocksForm,
  FlexibleContentBlocksCtaBlocks,
  FlexibleContentBlocksStarGuests,
  FlexibleContentBlocksFeatures,
  FlexibleContentBlocksSplitContent,
  FlexibleContentBlocksLogoGrid,
  FlexibleContentBlocksTextImage,
  FlexibleContentBlocksTextBlock,
  FlexibleContentBlocksFaq,
  FlexibleContentBlocksTabsLayout,
} from "graphql";
import { gql } from "@apollo/client";

interface BlocksProps {
  blocks: any[];
}

interface BlockProps {
  block: FlexibleContentBlocks_Layout;
}

const prefix = "FlexibleContentBlocks";
const Block = ({ block }: BlockProps) => {
  // @ts-ignore
  const { __typename } = block;

  let name = __typename && __typename.replace(prefix, "");

  switch (name) {
    // Hero
    case "Hero": {
      return <Hero {...(block as FlexibleContentBlocksHero)} />;
    }
    // Form
    case "Form": {
      return <Form {...(block as FlexibleContentBlocksForm)} />;
    }
    // CTABlock
    case "CtaBlocks": {
      return <CTABlock {...(block as FlexibleContentBlocksCtaBlocks)} />;
    }
    // Guests
    case "StarGuests": {
      return <Guests {...(block as FlexibleContentBlocksStarGuests)} />;
    }
    // Features Block
    case "Features": {
      return <FeaturesBlock {...(block as FlexibleContentBlocksFeatures)} />;
    }
    // Hero
    case "SplitContent": {
      return <SplitContent {...(block as FlexibleContentBlocksSplitContent)} />;
    }
    // Logos
    case "LogoGrid": {
      return <Logos {...(block as FlexibleContentBlocksLogoGrid)} />;
    }
    // Text Image
    case "TextImage": {
      return <TextImage {...(block as FlexibleContentBlocksTextImage)} />;
    }
    // Text Image
    case "TextBlock": {
      return <TextBlock {...(block as FlexibleContentBlocksTextBlock)} />;
    }
    // TabsBlock
    case "TabsLayout": {
      return <TabsBlock {...(block as FlexibleContentBlocksTabsLayout)} />;
    }
    // Faq
    case "Faq": {
      return <FAQ {...(block as FlexibleContentBlocksFaq)} />;
    }

    default: {
      return null;
    }
  }
};

const Blocks = ({ blocks = [] }: BlocksProps): JSX.Element => {
  return (
    <>
      {blocks &&
        blocks.map((block, index) => (
          <Block block={block} key={`block-${index}`} />
        ))}
    </>
  );
};

export default Blocks;

Blocks.fragments = {
  entry: gql`
    fragment BlocksFragment on FlexibleContent_Fields {
      blocks {
        ... on FlexibleContentBlocksHero {
          ...HeroFragment
        }
        ... on FlexibleContentBlocksForm {
          ...FormBlockFragment
        }
        ... on FlexibleContentBlocksCtaBlocks {
          ...CTABlockFragment
        }

        ... on FlexibleContentBlocksSplitContent {
          ...SplitContentFragment
        }
        ... on FlexibleContentBlocksFeatures {
          ...FeaturesBlockFragment
        }
        ... on FlexibleContentBlocksStarGuests {
          ...GuestsFragment
        }
        ... on FlexibleContentBlocksLogoGrid {
          ...LogosFragment
        }
        ... on FlexibleContentBlocksTextImage {
          ...TextImageFragment
        }
        ... on FlexibleContentBlocksTextBlock {
          ...TextBlockFragment
        }
        ... on FlexibleContentBlocksFaq {
          ...FAQFragment
        }
        ... on FlexibleContentBlocksTabsLayout {
          ...TabsBlockFragment
        }
      }
    }
    ${Hero.fragments.entry}
    ${CTABlock.fragments.entry}
    ${FeaturesBlock.fragments.entry}
    ${Guests.fragments.entry}
    ${SplitContent.fragments.entry}
    ${Logos.fragments.entry}
    ${TextImage.fragments.entry}
    ${TextBlock.fragments.entry}
    ${FAQ.fragments.entry}
    ${TabsBlock.fragments.entry}
    ${FORM_BLOCK_FRAGMENT}
  `,
};
