import { gql } from "@apollo/client";
import { FlexibleContentBlocksTextBlock } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";
import { BgGraphic } from "components/BgGraphic";

const superhero = {
  sourceUrl: "/superhero.svg",
  mediaDetails: { width: 1436, height: 1050 },
  altText: "Superhero graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const starburst = {
  sourceUrl: "/starburst.svg",
  mediaDetails: { width: 511, height: 426 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface TextBlockProps extends FlexibleContentBlocksTextBlock {
  className?: string;
}

const TextBlock = ({
  className,
  sectionTitle,
  sectionContent,
  ctas,
  backgroundColor,
  backgroundImage,
  fontColor,
}: TextBlockProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  let wrapperClass = clsx(
    `grid bg-${backgroundColor} items-center justify-center overflow-hidden relative`,
  );

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  let innerSectionClass = clsx(
    `flex flex-col items-center justify-between mx-auto gap-8 relative h-full max-w-screen-2xl px-4 md:px-8 py-7 relative z-10`,
  );
  let innerContentClass = clsx(`w-full`);
  let innerContentTitleClass = clsx(
    `font-heading text-4xl md:text-[3.5rem] leading-none text-center font-bold tracking-widest text-${fontColor}`,
  );
  let secContentClass = clsx(
    `font-body text-md tracking-wider mt-4 mb-6 text-${fontColor}`,
  );

  const noBgImage = backgroundImage && backgroundImage[0] === "none";
  let bgGraphicType = "";
  if (backgroundImage && backgroundImage[0] === "planet-rocketship") {
    bgGraphicType = "planet-rocketship";
  } else if (backgroundImage && backgroundImage[0] === "rocketship-planet") {
    bgGraphicType = "rocketship-planet";
  } else if (backgroundImage && backgroundImage[0] === "astronaut-comet") {
    bgGraphicType = "astronaut-comet";
  } else if (backgroundImage && backgroundImage[0] === "comet-astronaut") {
    bgGraphicType = "comet-astronaut";
  } else if (backgroundImage && backgroundImage[0] === "radar-spaceshuttle") {
    bgGraphicType = "radar-spaceshuttle";
  } else if (backgroundImage && backgroundImage[0] === "spaceshuttle-radar") {
    bgGraphicType = "spaceshuttle-radar";
  } else if (backgroundImage && backgroundImage[0] === "comet-right") {
    bgGraphicType = "comet-right";
  } else if (backgroundImage && backgroundImage[0] === "comet-left") {
    bgGraphicType = "comet-left";
  } else if (backgroundImage && backgroundImage[0] === "radar-right") {
    bgGraphicType = "radar-right";
  } else if (backgroundImage && backgroundImage[0] === "radar-left") {
    bgGraphicType = "radar-left";
  }

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={`${className} ${innerSectionClass}`}>
        <div className={innerContentClass}>
          {sectionTitle && (
            // <h3 className={innerContentTitleClass}>{sectionTitle}</h3>
            <Content
              className={innerContentTitleClass}
              content={sectionTitle}
            />
          )}
          {sectionContent && (
            <Content className={secContentClass} content={sectionContent} />
          )}

          {ctas &&
            ctas.map((cta, index) => {
              const link = cta?.link;
              const isPrimary = cta?.type && cta?.type[0] === "primary";
              const isSecondary = cta?.type && cta?.type[0] === "secondary";

              let btnType = clsx(
                isPrimary &&
                  `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-bold text-white font-sans uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
                isSecondary &&
                  `bg-white rounded-md border-2 border-secondary py-2 px-4 font-bold text-secondary font-sans uppercase hover:bg-secondary hover:text-white hover:border-secondary transition duration-300 ease-in-out tracking-wider`,
              );

              return (
                <Link
                  key={`${index}-${cta}`}
                  href={link?.url ?? ""}
                  target={link?.target ?? "_self"}
                  className={btnType}
                >
                  {link?.title}
                </Link>
              );
            })}
        </div>
      </div>

      {!noBgImage && (
        <div className={bgItemClass} style={{ gridArea: "1/1" }}>
          <BgGraphic bgType={bgGraphicType} />
        </div>
      )}
    </section>
  );
};

export default TextBlock;

TextBlock.fragments = {
  entry: gql`
    fragment TextBlockFragment on FlexibleContentBlocksTextBlock {
      __typename
      sectionTitle
      sectionContent
      ctas {
        type
        link {
          title
          url
          target
        }
      }
      backgroundColor
      backgroundImage
      fontColor
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
