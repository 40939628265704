import clsx from "clsx";
import { Content } from "components/Content";
import Link from "next/link";
import { FeaturedImage } from "components/FeaturedImage";
import Image from "next/image";
// import { Gallery } from "components/Carousel";
import { Carousel } from "components/Carousel";
import { AcfLink, MediaItem } from "graphql";
import { FaAngleRight, FaAngleUp, FaAngleDown } from "react-icons/fa";
import useMediaQuery from "utilities/useMediaQuery";
import { m } from "framer-motion";
import { useState } from "react";
import removeAmp from "utilities/removeAmp";
import { SITE_SETTINGS_FRAGMENT } from "fragments";

const starburst = {
  sourceUrl: "/starburst.svg",
  mediaDetails: { width: 511, height: 426 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

interface CelebsProps {
  className?: string;
  siteSettings: {
    siteSettingFields: {
      ticketsOnSale: boolean;
      generalAdmissionTickets: AcfLink;
      autographTickets: AcfLink;
      photoOpTickets: AcfLink;
    };
  };
  celebrity: {
    title: string;
    guestProps: {
      guestDays: {
        guestDate: string;
      }[];
      headshot: {
        node: {
          sourceUrl: string;
          mediaDetails: {
            width: number;
            height: number;
          };
          altText: string;
          sizes: string;
        };
      };
      hasBio: boolean;
      imdbLink: AcfLink;
      bio: string;
      ticketAnnouncement: string;
      ticketDisclaimer: string;
      autographBlurb: string;
      photoOpsBlurb: string;
      additionalTicketsBlurb: string;
      ticketsType: string;
      hasTicketsReady: boolean;
      autographPrice: number;
      photoOpPrice: number;
      autographTicketTypesAndPrices: {
        aboutType: string;
        ticketType: string;
        price: number;
      }[];
      photoOpTicketTypesAndPrices: {
        aboutType: string;
        ticketType: string;
        price: number;
      }[];
      customAutographLink: AcfLink;
      customPhotoOpsLink: AcfLink;
      galleryImages: MediaItem[];
    };
  };
}

const Guests = ({ className, celebrity, siteSettings }: CelebsProps) => {
  const {
    ticketsOnSale,
    generalAdmissionTickets,
    autographTickets,
    photoOpTickets,
  } = siteSettings.siteSettingFields;
  const { title, guestProps } = celebrity;
  const {
    guestDays,
    headshot,
    hasBio,
    imdbLink,
    bio,
    ticketAnnouncement,
    ticketDisclaimer,
    autographBlurb,
    photoOpsBlurb,
    additionalTicketsBlurb,
    ticketsType,
    hasTicketsReady,
    autographPrice,
    photoOpPrice,
    autographTicketTypesAndPrices,
    photoOpTicketTypesAndPrices,
    customAutographLink,
    customPhotoOpsLink,
    galleryImages,
  } = guestProps;

  const imbdLink = imdbLink?.url ?? "";
  const showTix = ticketsType && ticketsType[0] === "basic-show";
  const autoPhotoTix = ticketsType && ticketsType[0] === "basic-auto-photo";
  const customTix = ticketsType && ticketsType[0] === "custom-auto-photo";

  const [isOpen, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 868px)");

  let wrapperClass = clsx(`bg-white grid overflow-hidden relative`);

  let innerWrapperClass = clsx(
    `flex flex-col mdl:flex-row gap-8 items-start justify-center w-[97.5vw] max-w-screen-2xl mx-auto px-4 md:px-8 py-6 z-10 relative h-full`,
  );

  let secTitleClass = clsx(
    `w-full p-2 text-center bg-primary`,
    isMobile && `mb-4`,
  );
  let titleClass = clsx(
    `font-heading text-4xl sm:text-5xl font-bold text-center text-slate tracking-widest drop-shadow-primary`,
  );

  let celebItemImgClass = clsx(`w-full mdl:w-[40%] h-full grayscale`);

  let celebItemContentClass = clsx(`w-full mdl:w-[60%] text-primary`);

  let sectionContent = clsx(
    `flex flex-col gap-2 p-4 border-b-primary border-b-2 last-of-type:border-b-0`,
  );

  let sectionSideContent = clsx(`flex flex-col gap-2 p-4`);

  let boldedSubtitle = clsx(`text-primary font-body mb-0 mt-4 font-semibold`);

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  let btnLinkClass = clsx(
    `w-fit rounded-md bg-secondary border-2 border-secondary py-1 px-2 md:py-2 md:px-4 text-xs md:text-[16px] font-bold text-black font-body uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
  );

  const autoDecimal = autographPrice % 1;
  const autoDecimalLength = autoDecimal.toString().length;

  let adjAutoPrice = "";
  if (autoDecimal) {
    if (autoDecimalLength === 3) {
      adjAutoPrice = autographPrice?.toString() + "0";
    } else {
      adjAutoPrice = autographPrice?.toString();
    }
  } else {
    adjAutoPrice = autographPrice?.toString();
  }

  const photoDecimal = photoOpPrice % 1;
  const photoDecimalLength = photoDecimal.toString().length;

  let adjPhotoPrice = "";
  if (photoDecimal) {
    if (photoDecimalLength === 3) {
      adjPhotoPrice = photoOpPrice?.toString() + "0";
    } else {
      adjPhotoPrice = photoOpPrice?.toString();
    }
  } else {
    adjPhotoPrice = photoOpPrice?.toString();
  }


  const renderImageSlide = (galleryImage: MediaItem, index: number) => {
    const { altText, sourceUrl } = galleryImage || {};
    const imageProps = {
      src: sourceUrl || "",
      alt: altText || "",
    };
    const imageClass = clsx("w-full h-full");
    return (
      <div
        key={index}
        className={`relative overflow-hidden w-full h-[275px] mdl:h-[20vw]`}
      >
        <Image
          className={imageClass}
          {...imageProps}
          fill={true}
          loading="eager"
          style={{ objectFit: "cover" }}
        />
      </div>
    );
  };

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      <div className={innerWrapperClass}>
        <div className={celebItemImgClass}>
          {isMobile && (
            <div className={secTitleClass}>
              <h3 className={titleClass}>{title}</h3>
            </div>
          )}

          <FeaturedImage className={``} image={headshot.node} />

          <div className={`${sectionSideContent}`}>
            <h3 className={`text-3xl text-primary`}>APPEARANCE INFORMATION</h3>
            <p className={boldedSubtitle}>Days Appearing:</p>
            {guestDays ? (
              guestDays.map((day, index) => {
                const trimDate = day.guestDate.split("T")[0];
                const [gyear, gmonth, gday] = trimDate.split("-");
                const formDate = `${gmonth}/${gday}/${gyear}`;
                const parseDate = Date.parse(formDate);
                const formattedDate = new Date(parseDate).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  },
                );

                return (
                  <p key={index} className={`mb-0 font-body`}>
                    {formattedDate}
                  </p>
                );
              })
            ) : (
              <p className={boldedSubtitle}>Dates Coming Soon!</p>
            )}
          </div>
        </div>
        <div className={celebItemContentClass}>
          {!isMobile && (
            <div className={secTitleClass}>
              <h3 className={titleClass}>{title}</h3>
            </div>
          )}
          {(hasBio || imbdLink) && (
              <div className={sectionContent}>
                <p className={boldedSubtitle}>About {title}</p>
                {hasBio ? (
                  <m.div
                    layout
                    style={{ height: isOpen ? "fit-content" : "120px" }}
                    onClick={() => setOpen(!isOpen)}
                    className={`relative overflow-hidden pb-8 transition duration-300 ease-in-out`}
                  >
                    <m.div
                      layout
                      style={{ height: isOpen ? "fit-content" : "100px" }}
                      className={`relative`}
                    >
                      <Content className={`font-body`} content={bio} />
                    </m.div>
                    <m.div
                      className={`absolute bottom-0 left-0 text-xl bg-gradiant-fade flex flex-row items-center w-full font-body underline pt-4 text-primary-light transition duration-300 ease-in-out cursor-pointer`}
                    >
                      {isOpen ? "Read Less " : "Read more "}
                      {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                    </m.div>
                  </m.div>
                ) : (
                  imbdLink && (
                    <Link href={imbdLink} target={"_blank"}>
                      <p
                        className={`text-primary-light hover:text-secondary font-body underline inline-flex items-center transition duration-300 ease-in-out`}
                      >
                        IMDB Profile <FaAngleRight />
                      </p>
                    </Link>
                  )
                )}
              </div>
            )}
          <div className={sectionContent}>
            {ticketAnnouncement && (
              <p className={`mb-0 text-xl font-bold font-body`}>
                {ticketAnnouncement}
              </p>
            )}
            {ticketsOnSale && (
              <>
                {showTix && (
                  <>
                    <p className={boldedSubtitle}>Buy Show Tickets</p>
                    <Link
                      href={generalAdmissionTickets?.url ?? ""}
                      target={generalAdmissionTickets?.target ?? "_self"}
                      className={btnLinkClass}
                    >
                      {/* {generalAdmissionTickets?.title} */}
                      {removeAmp(generalAdmissionTickets?.title as string)}
                    </Link>
                  </>
                )}
                {autoPhotoTix && (
                  <>
                    <p className={boldedSubtitle}>
                      Autographs: ${adjAutoPrice}
                    </p>
                    {autographBlurb && (
                      <Content
                        className={`font-body`}
                        content={autographBlurb}
                      />
                    )}
                    {hasTicketsReady && (
                      <Link
                        href={autographTickets?.url ?? ""}
                        target={autographTickets?.target ?? "_self"}
                        className={btnLinkClass}
                      >
                        {/* {autographTickets?.title} */}
                        {removeAmp(autographTickets?.title as string)}
                      </Link>
                    )}
                    <p className={boldedSubtitle}>Photo Ops: ${adjPhotoPrice}</p>
                    {photoOpsBlurb && (
                      <Content
                        className={`font-body`}
                        content={photoOpsBlurb}
                      />
                    )}
                    {hasTicketsReady && (
                      <Link
                        href={photoOpTickets?.url ?? ""}
                        target={photoOpTickets?.target ?? "_self"}
                        className={btnLinkClass}
                      >
                        {/* {photoOpTickets?.title} */}
                        {removeAmp(photoOpTickets?.title as string)}
                      </Link>
                    )}
                  </>
                )}
                {customTix && (
                  <>
                  {autographTicketTypesAndPrices && autographTicketTypesAndPrices.map((ticket, index) => {
                    const { ticketType, price, aboutType } = ticket;

                    const decimal = price % 1;
                    const decimalLength = decimal.toString().length;

                    let adjPrice = "";
                    if (decimal) {
                      if (decimalLength === 3) {
                        adjPrice = price.toString() + "0";
                      } else {
                        adjPrice = price.toString();
                      }
                    } else {
                      adjPrice = price.toString();
                    }

                    return (
                      <div key={index} className={``}>
                        <p className={boldedSubtitle}>
                          {ticketType}: ${adjPrice}
                        </p>
                        <Content
                          className={`font-body`}
                          content={aboutType}
                        />
                      </div>
                    );
                  })}
                    {autographBlurb && (
                      <Content
                        className={`font-body`}
                        content={autographBlurb}
                      />
                    )}
                    {hasTicketsReady && customAutographLink && (
                      <Link
                        href={customAutographLink?.url ?? ""}
                        target={customAutographLink?.target ?? "_self"}
                        className={btnLinkClass}
                      >
                        {/* {customAutographLink?.title} */}
                        {removeAmp(customAutographLink?.title as string)}
                      </Link>
                    )}
                    {photoOpTicketTypesAndPrices && photoOpTicketTypesAndPrices.map((ticket, index) => {
                    const { ticketType, price, aboutType } = ticket;

                    const decimal = price % 1;
                    const decimalLength = decimal.toString().length;

                    let adjPrice = "";
                    if (decimal) {
                      if (decimalLength === 3) {
                        adjPrice = price.toString() + "0";
                      } else {
                        adjPrice = price.toString();
                      }
                    } else {
                      adjPrice = price.toString();
                    }

                    return (
                      <div key={index} className={``}>
                        <p className={boldedSubtitle}>
                          {ticketType}: ${adjPrice}
                        </p>
                        <Content
                          className={`font-body`}
                          content={aboutType}
                        />
                      </div>
                    );
                  })}
                    {photoOpsBlurb && (
                      <Content
                        className={`font-body`}
                        content={photoOpsBlurb}
                      />
                    )}
                    {hasTicketsReady && customPhotoOpsLink && (
                      <Link
                        href={customPhotoOpsLink?.url ?? ""}
                        target={customPhotoOpsLink?.target ?? "_self"}
                        className={btnLinkClass}
                      >
                        {/* {customPhotoOpsLink?.title} */}
                        {removeAmp(customPhotoOpsLink?.title as string)}
                      </Link>
                    )}
                    {/* {hasSecondaryTicketLink && (
                      <>
                        <p className={boldedSubtitle}>
                          {secondaryTicketType} ${photoOpPrice}
                        </p>
                        {secondaryTicketBlurb && (
                          <Content
                            className={`font-body`}
                            content={secondaryTicketBlurb}
                          />
                        )}
                        {hasTicketsReady && (
                          <Link
                            href={customSecondaryLink?.url ?? ""}
                            target={customSecondaryLink?.target ?? "_self"}
                            className={btnLinkClass}
                          >
                            {customSecondaryLink?.title}
                          </Link>
                        )}
                      </>
                    )} */}
                  </>
                )}
              </>
            )}

            {ticketDisclaimer && (
              <Content className={`font-body`} content={ticketDisclaimer} />
            )}
          </div>
          {galleryImages && (
            <div className={sectionContent}>
              {/* <Gallery
                className={`celeb-gallery`}
                images={galleryImages}
                numNgroups={"3"}
              /> */}
              <Carousel
                items={galleryImages ?? []}
                renderSlide={renderImageSlide}
                numNgroups={3}
                arrows
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className={bgItemClass} style={{ gridArea: "1/1" }}>
        <FeaturedImage
          className={`absolute top-2 -left-40 -scale-x-100 w-[25rem]`}
          image={starburst}
        />
        <FeaturedImage
          className={`absolute bottom-2 -right-40 -scale-y-100 w-[25rem]`}
          image={starburst}
        />
      </div> */}
    </section>
  );
};

export default Guests;
